// components/ViewSelector.tsx
import React from 'react';
import { IonRadioGroup, IonRadio, IonItem } from '@ionic/react';
import StepsLabel from './StepsLabel';
import { useRecoilState } from 'recoil';
import { showReportState, viewState } from '../recoil/atoms';

const ViewSelector: React.FC = () => {
  const [view, setView] = useRecoilState(viewState);

  return (
    <div>
      <StepsLabel stepTitle="3. Select View" StepSubtitle="What do you want to see?" />
      <IonRadioGroup value={view} onIonChange={(e) => setView(e.detail.value)}>
        <IonItem lines="none" disabled={!showReportState} style={{ '--min-height': '0px' }}>
          <IonRadio labelPlacement="end" slot="start" value="details">
            Details (Separate reports for each selected employee)
          </IonRadio>
        </IonItem>
        <IonItem lines="none" disabled={!showReportState} style={{ '--min-height': '0px' }}>
          <IonRadio labelPlacement="end" slot="start" value="overall">
            Overall (One report for all selected employees)
          </IonRadio>
        </IonItem>
      </IonRadioGroup>
    </div>
  );
};

export default ViewSelector;
