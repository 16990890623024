// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files
const resources = {
  en: {
    translation: {
      "addFirstList": "Tap the \"+\" <br /> to add a list",
      "addFirstTask": "Tap the \"+\" <br /> to add a task",
      "addList": "Add List",
      "addTask": "Add Task",
      "banner.governor.pastDue.accountLocked":
        "Sorry, your company's fees have been unpaid for {{count}} days, so <b>your account is locked</b>. Users can no longer login to the Otuvy app. Please update your card on file to unlock your account.",
      "banner.governor.pastDue.limitExceeded_zero":
        "Your account is past due. Please update your payment info or your access will be limited on {{date}}.",
      "banner.governor.pastDue.limitExceeded_one":
        "Your account is {{count}} day past due. Please update your payment info or your access will be limited on {{date}}.",
      "banner.governor.pastDue.limitExceeded_other":
        "Your account is {{count}} days past due. Please update your payment info or your access will be limited on {{date}}.",
      "banner.governor.pastDue.writeAccessRestricted":
        "Sorry, your company's fees have been unpaid for {{count}} days, so <b>access has been limited</b> for you and your employees. Please update your card on file to avoid your account being locked.",
      "button.acknowledge": "OK",
      "button.cancel": "Cancel",
      "button.clear": "Clear/Reset",
      "button.delete": "Delete",
      "button.download.template": "Download Import Template (.xls)",
      "button.edit": "Edit",
      "button.paymentInfo.update": "Update Payment Info",
      "button.resendInvite": "Resend Invite",
      "button.user.activate": "Activate",
      "button.user.add_zero": "Add",
      "button.user.add_one": "Add {{count}} User",
      "button.user.add_other": "Add {{count}} Users",
      "button.user.deactivate": "Deactivate",
      "cancel": "Cancel",
      "complete": "Complete",
      "completed": "Completed",
      "completeTask": "Complete Task",
      "completeTasks": "Complete Tasks",
      "completeList": "Complete List",
      "confirmation.delete.image.confirm": "Delete image",
      "confirmation.delete.image.header": "Delete Image",
      "confirmation.delete.image.message":
        "You will not be able to recover this image once deleted.",
      "confirmation.delete.list.confirm": "Delete the list",
      "confirmation.delete.list.confirm_one": "Delete the list",
      "confirmation.delete.list.confirm_other": "Delete the lists",
      "confirmation.delete.list.header": "Delete this list?",
      "confirmation.delete.list.header_one": "Delete this list?",
      "confirmation.delete.list.header_other": "Delete these lists?",
      "confirmation.delete.list.message":
        "All of its tasks (and their pics, notes, etc...) will be deleted as well.",
      "confirmation.delete.list.message_one":
        "All of its tasks (and their pics, notes, etc...) will be deleted as well.",
      "confirmation.delete.list.message_other":
        "All of their tasks (and their pics, notes, etc...) will be deleted as well.",
      "confirmation.delete.person.confirm1": "Yes, transfer their lists to me",
      "confirmation.delete.person.confirm2": "NO, delete their lists",
      "confirmation.delete.person.header": "Delete User",
      "confirmation.delete.person.message":
        "Would you like to keep this user's lists and transfer them to yourself?<br/><br/>(If not, the lists will be deleted.)<br/><br/>(Note: you can go change the list owners first, on the Lists page.)",
      "confirmation.delete.task.confirm": "Delete task",
      "confirmation.delete.task.header": "Delete Task",
      "confirmation.delete.task.message":
        "Are you sure? (All pics, notes, etc... will be deleted as well.)",
      "confirmation.delete.task.generic.confirm": "Delete",
      "confirmation.delete.task.generic.header": "Confirm Deletion",
      "confirmation.delete.task.generic.message":
        "Are you sure? (All pics, notes, etc... will be deleted as well.)",
      "contactSupport": "Contact Support",
      "darkMode": "Dark Mode",
      "delete": "Delete",
      "deleteTasks": "Delete Tasks",
      "deselectAll": "Deselect All",
      "done": "Done",
      "duplicate": "Duplicate",
      "duplicateTask": "Duplicate Task",
      "duplicateTasks": "Duplicate Tasks",
      "duplicateList": "Duplicate List",
      "edit": "Edit",
      "email": "Email",
      "enum.status.active": "Active",
      "enum.status.inactive": "Inactive",
      "enum.userRole.admin": "Admin",
      "enum.userRole.employee": "Employee",
      "enum.userRole.super_admin": "Super-Admin",
      "error.email.duplicate": "Email addresses must be unique",
      "error.email.invalid": "Valid email address required",
      "error.generic.required": "{{field}} is required",
      "error.language.unsupported":
        "Only English, Spanish, French, and Portuguese are currently supported",
      "error.list.listName.required": "No List Name",
      "error.list.listOwner.noMatchingEmail":
        "\"List Owner\" email doesn’t match any users",
      "error.list.listOwner.required": "No List Owner",
      "error.list.sharedWith.noMatchingEmail":
        "\"Share List with\" email doesn’t match any users",
      "error.name.required": "Name is Required",
      "error.phone.invalid": "Phone appears to be invalid",
      "error.phone.required": "Phone is Required",
      "error.signin.incorrectOTP":
        "Incorrect. Please double check the code from your email and enter it again.",
      "errorAlert.default.header": "Whoops!",
      "errorAlert.default.message":
        "We ran into an error on our side. Sorry about that! If it happens multiple times, please feel free to <a href='mailto:support@otuvy.com'>Contact Support</a>.",
      "errorAlert.generic.header.error": "Error",
      "errorAlert.generic.header.errors": "Error(s)",
      "errorAlert.generic.contactSupport":
        "We apologize for the inconvenience. An error has occurred. In order to resolve this error, please <a href=\"mailto:support@otuvy.com\">Contact Support</a>.",
      "errorAlert.generic.happensAgainContactSupport":
        "If it happens multiple times, please feel free to <a href=\"mailto:support@otuvy.com\">Contact Support</a>.",
      "errorAlert.generic.tryAgain":
        "We apologize, an error has occurred on our side. Please wait a few moments and then try again.",
      "errorAlert.import.table.duplicates.email.header": "Duplicate emails in your data",
      "errorAlert.import.table.duplicates.email.message1":
        "The following emails are duplicated in your data:",
      "errorAlert.import.table.duplicates.email.message2":
        "Please correct (or remove) the duplicates and try again.",
      "errorAlert.import.table.inUse.email.header": "Emails exist already",
      "errorAlert.import.table.inUse.email.message1":
        "The following employees are already working for another organization:",
      "errorAlert.import.table.inUse.email.message2":
        "Please have them ask to be removed from the other organization first, or use a different email for them.",
      "errorAlert.import.table.fixThem":
        "Please correct any errors (usually highlighted in red), then try to submit it again.",
      "errorPage": "Hi, I'm the Error Page.",
      "errorPage.backIntoIt": "Let's get back into it.",
      "errorPage.sorry":
        "Sorry about that, we had a hiccup on our end. Don't worry, your work should save automatically.",
      "feedback.list.completed": "Great work! You've completed the list.",
      "feedback.list.import.success": "{{count}} lists uploaded successfully!",
      "feedback.person.added":
        "User added! See your invoice for updates.<br/><br/>An invitation has been sent to their email.",
      "feedback.person.deleted": "User removed. Your bill will be reduced accordingly.",
      "feedback.person.edited": "Changes Saved",
      "feedback.person.imported.acknowledge": "View People",
      "feedback.person.imported.created.message_one":
        "One user added/updated! See your invoice for updates.<br/><br/>An invitation has been sent to their email.",
      "feedback.person.imported.created.message_other":
        "{{count}} users added/updated! See your invoice for updates.<br/><br/>Invitations have been sent to their emails.",
      "feedback.person.imported.edited.message_one": "One user was edited.",
      "feedback.person.imported.edited.message_other": "{{count}} users were edited",
      "feedback.person.imported.reactivated.message_one": "One user was reactivated.",
      "feedback.person.imported.reactivated.message_other": "{{count}} users were reactivated",
      "feedback.person.welcomeEmailsResent_one": "{{count}} Email Sent!",
      "feedback.person.welcomeEmailsResent_other": "{{count}} Emails Sent!",
      "feedback.success.withFeeling": "Success!",
      "feedback.task.completed": "Great work! Task completed: \"{{taskName}}\"",
      "grid.column.header.user.email": "Email",
      "grid.column.header.user.firstName": "First Name",
      "grid.column.header.user.language": "Language",
      "grid.column.header.user.lastName": "Last Name",
      "home": "Home",
      "info.amountSelected": "{{count}} Selected",
      "label.list.list": "List",
      "label.list.owner": "Owner",
      "label.selectAllCompletedTasks": "Select all completed tasks",
      "label.selectAllTasks": "Select all tasks",
      "label.task.instructions": "Instructions",
      "label.task.name": "Task",
      "label.task.taskName": "Task",
      "label.task.notes": "Notes",
      "label.usage.admins.requiredAmount": "Admins (1 required)",
      "label.usage.employees": "Employees",
      "label.usage.lists": "Lists",
      "label.usage.storage": "Storage Space",
      "label.usage.tasks": "Tasks",
      "label.usage.totalUsers": "Total Users",
      "label.user.actions": "Actions",
      "label.user.email": "Email",
      "label.user.firstName": "First Name",
      "label.user.inactive": "Inactive",
      "label.user.language": "Language",
      "label.user.lastName": "Last Name",
      "label.user.role": "Role",
      "language": "Language",
      "language.name.en": "English",
      "language.name.es": "Spanish",
      "language.name.fr": "French",
      "language.name.pt": "Portuguese",
      "listName": "Name of List",
      "list.rename": "Rename List",
      "lists": "Lists",
      "loading": "Loading...",
      "loading.message.user.import": "Importing your users.",
      "loading.message.list.import": "Creating your lists...",
      "logout": "Log out",
      "navigation.add": "Add",
      "navigation.import": "Import",
      "navigation.view": "View",
      "newCodeRequest": "Send me a new code",
      "newVerificationCodeSent.header": "We've sent the code.",
      "newVerificationCodeSent.message":
        "Please check your email inbox for the new code. It may have landed in your junk folder. It does that sometimes.",
      "offlineNoticeHeader": "No internet :(",
      "offlineNoticeMessage":
        "We should reconnect soon. To find out if it's not just our app, you can... <br/> <br/> <ul><li> Check your phone settings</li><li>Check other Apps</li><li>Check WiFi signal strength</li></ul><br/>And don't worry we save your work as we go!",
      "otpCode": "Code",
      "otpPrompt": "We'll send a code to your email so you can start using Otuvy Frontline",
      "otpSent":
        "We've sent a verification code to your email. Please enter it below.",
      "phone": "Phone",
      "page.listImport.dropzone.message":
        "Drag your Excel file here, or click to upload one.",
      "page.listImport.dropzone.message2": "(.XLS or .XLSX files only)",
      "page.listImport.dialog.error.fileType.header":
        "Error: .XLS or .XLSX files only",
      "page.listImport.dialog.error.fileType.message":
        "<p>To upload successfully, your data will need to be in an Excel file format (.XLS or .XLSX).</p><p>If you don’t have Excel, try copying your data into a Google Sheet then downloading it as an Excel file: File > Download > Microsoft Excel (.xlsx)</p><p>If that doesn't work and you still have questions, please feel free to contact support at <a href='mailto:support@otuvy.com'>support@otuvy.com</a> or <a href='tel:8018743443'>801.874.3443</a>.</p>",
      "page.listImport.panel.instructions.header": "Preparing your Lists for Import",
      "page.listImport.panel.instructions.instruction1":
        "1. <strong>Download</strong> the Template.",
      "page.listImport.panel.instructions.instruction1.sub":
        "<strong>Did you know?</strong> You can export a Google Sheet as an Excel File!",
      "page.listImport.panel.instructions.instruction2":
        "2. <strong>Copy/paste</strong> your data into it, and save the file.",
      "page.listImport.panel.instructions.instruction2.sub1.description":
        "The name of the list of tasks (Tip: Customers may see this.)",
      "page.listImport.panel.instructions.instruction2.sub1.label":
        "List Name",
      "page.listImport.panel.instructions.instruction2.sub2.description":
        "Email address - the person responsible for the list.",
      "page.listImport.panel.instructions.instruction2.sub2.label":
        "List Owner",
      "page.listImport.panel.instructions.instruction2.sub3.description":
        "Email addresses - other people who should see the list.",
      "page.listImport.panel.instructions.instruction2.sub3.label":
        "Share List with",
      "page.listImport.panel.instructions.instruction2.sub4.description":
        "Enter one task per row, going down.",
      "page.listImport.panel.instructions.instruction2.sub4.label": "Tasks",
      "page.listImport.panel.instructions.instruction3":
        "3. <strong>Upload</strong> the saved file above.",
      "page.listImport.panel.validation.header":
        "Your import data needs attention.",
      "page.listImport.panel.validation.instruction1":
        "1. Fix the following errors in your Excel file:",
      "page.listImport.panel.validation.instruction2":
        "2. <strong>Upload</strong> the saved file (with the fixes you just made) below.",
      "page.listImport.panel.validation.message":
        "Your lists were not imported due to errors that need to be fixed in your data.",
      "page.listImport.panel.validation.table.header.columnName": "Column",
      "page.listImport.panel.validation.table.header.email": "Incorrect Email",
      "page.listImport.panel.validation.table.header.errorMessage": "Error",
      "page.listImport.panel.validation.table.header.listName": "List Name",
      "picturesAbbreviated": "Pics",
      "placeholder.changeOwner": "Change Owner to:",
      "placeholder.email": "jane.doe@example.com",
      "placeholder.firstName": "Jane",
      "placeholder.generic": "Tap to enter text",
      "placeholder.language": "Select language",
      "placeholder.lastName": "Doe",
      "placeholder.name": "Jane Doe",
      "placeholder.otp": "Your verification code",
      "placeholder.selectOne": "Select one...",
      "product.version": "v.",
      "requestToWait": "Please wait...",
      "resendingInvite": "Resending Invite...",
      "select": "Select",
      "selectAll": "Select All",
      "sendCode": "Send Verification Code",
      "settings": "Settings",
      "status": "Status",
      "submit": "Submit",
      "tasks": "Tasks",
      "title.billing": "Billing",
      "title.lists": "Lists",
      "title.people": "People",
      "title.settings": "Settings",
      "title.timeTracking": "Time Tracking",
      "title.usage": "Usage",
      "title.integrations.qm": "Otuvy QM Integration",
      "title.integrations.qm.settings": "Settings",
      "title.integrations.qm.locations": "Locations",
      "undefinedList.name": "Undefined List",
      "unit.gigabyte": "GB",
      "unit.unlimited": "Unlimited",
      "wifiOnly": "Use Wifi Only",
      "wifiOnlyHint":
        "If you want the app to use your cell phone data, leave this option disabled. If you want to preserve your cell data, enable it."
    }
  },

  es: {
    translation: {
      // Existing + newly added Spanish keys

      "addFirstList": "Presione \"+\" <br /> para agregar una lista",
      "addFirstTask": "Presione \"+\" <br /> para agregar una tarea",
      "addList": "Agregar Lista",
      "addTask": "Agregar Tarea",

      "banner.governor.pastDue.accountLocked":
        "Lo sentimos, las tarifas de su empresa no se han pagado durante {{count}} días, así que <b>su cuenta está bloqueada</b>. Los usuarios ya no pueden iniciar sesión en la app de Otuvy. Actualice su tarjeta archivada para desbloquear su cuenta.",
      "banner.governor.pastDue.limitExceeded_zero":
        "Su cuenta está vencida. Actualice su información de pago o su acceso se limitará el {{date}}.",
      "banner.governor.pastDue.limitExceeded_one":
        "Su cuenta tiene un día de retraso. Actualice su información de pago o su acceso se limitará el {{date}}.",
      "banner.governor.pastDue.limitExceeded_other":
        "Su cuenta tiene {{count}} días de retraso. Actualice su información de pago o su acceso se limitará el {{date}}.",
      "banner.governor.pastDue.writeAccessRestricted":
        "Lo sentimos, las tarifas de su empresa no se han pagado durante {{count}} días, así que <b>el acceso ha sido limitado</b> para usted y sus empleados. Actualice su tarjeta archivada para evitar que su cuenta sea bloqueada.",

      "button.acknowledge": "Aceptar",
      "button.cancel": "Cancelar",
      "button.clear": "Borrar/Reiniciar",
      "button.delete": "Eliminar",
      "button.download.template": "Descargar Plantilla de Importación (.xls)",
      "button.edit": "Editar",
      "button.paymentInfo.update": "Actualizar Información de Pago",
      "button.resendInvite": "Reenviar invitación",
      "button.user.activate": "Activar",
      "button.user.add_zero": "Agregar",
      "button.user.add_one": "Agregar {{count}} Usuario",
      "button.user.add_other": "Agregar {{count}} Usuarios",
      "button.user.deactivate": "Desactivar",

      "cancel": "Cancelar",
      "complete": "Completar",
      "completed": "Completadas",
      "completeTask": "Completar Tarea",
      "completeTasks": "Completar Tareas",
      "completeList": "Completar Lista",

      "confirmation.delete.image.confirm": "Eliminar imagen",
      "confirmation.delete.image.header": "Eliminar Imagen",
      "confirmation.delete.image.message":
        "No podrá recuperar esta imagen una vez eliminada.",

      "confirmation.delete.list.confirm": "Eliminar la lista",
      "confirmation.delete.list.confirm_one": "Eliminar la lista",
      "confirmation.delete.list.confirm_other": "Eliminar las listas",
      "confirmation.delete.list.header": "¿Eliminar esta lista?",
      "confirmation.delete.list.header_one": "¿Eliminar esta lista?",
      "confirmation.delete.list.header_other": "¿Eliminar estas listas?",
      "confirmation.delete.list.message":
        "Todas sus tareas (y sus fotos, notas, etc.) también se eliminarán.",
      "confirmation.delete.list.message_one":
        "Todas sus tareas (y sus fotos, notas, etc.) también se eliminarán.",
      "confirmation.delete.list.message_other":
        "Todas sus tareas (y sus fotos, notas, etc.) también se eliminarán.",

      "confirmation.delete.person.confirm1": "Sí, transferir sus listas a mí",
      "confirmation.delete.person.confirm2": "NO, eliminar sus listas",
      "confirmation.delete.person.header": "Eliminar Usuario",
      "confirmation.delete.person.message":
        "¿Desea conservar las listas de este usuario y transferirlas a usted?<br/><br/>(Si no, las listas se eliminarán.)<br/><br/>(Nota: puede cambiar los propietarios de las listas primero, en la página Listas.)",

      "confirmation.delete.task.confirm": "Eliminar tarea",
      "confirmation.delete.task.header": "Eliminar Tarea",
      "confirmation.delete.task.message":
        "¿Está seguro? (Todas las fotos, notas, etc. también se eliminarán.)",
      "confirmation.delete.task.generic.confirm": "Eliminar",
      "confirmation.delete.task.generic.header": "Confirmar Eliminación",
      "confirmation.delete.task.generic.message":
        "¿Está seguro? (Todas las fotos, notas, etc. también se eliminarán.)",

      "contactSupport": "Contactar a Soporte",
      "darkMode": "Modo Oscuro",
      "delete": "Eliminar",
      "deleteTasks": "Eliminar Tareas",
      "deselectAll": "Deseleccionar Todo",
      "done": "Hecho",
      "duplicate": "Duplicar",
      "duplicateTask": "Duplicar Tarea",
      "duplicateTasks": "Duplicar Tareas",
      "duplicateList": "Duplicar Lista",
      "edit": "Editar",
      "email": "Correo electrónico",

      "enum.status.active": "Activo",
      "enum.status.inactive": "Inactivo",
      "enum.userRole.admin": "Admin",
      "enum.userRole.employee": "Empleado",
      "enum.userRole.super_admin": "Súper-Admin",

      "error.email.duplicate": "Las direcciones de correo electrónico deben ser únicas",
      "error.email.invalid": "Se requiere una dirección de correo electrónico válida",
      "error.generic.required": "{{field}} es obligatorio",
      "error.language.unsupported":
        "Actualmente solo se admiten inglés, español, francés y portugués",
      "error.list.listName.required": "No hay Nombre de Lista",
      "error.list.listOwner.noMatchingEmail":
        "El correo electrónico del \"Propietario de la Lista\" no coincide con ningún usuario",
      "error.list.listOwner.required": "No hay Propietario de la Lista",
      "error.list.sharedWith.noMatchingEmail":
        "El correo electrónico de \"Compartir Lista con\" no coincide con ningún usuario",
      "error.name.required": "El nombre es obligatorio",
      "error.phone.invalid": "El teléfono parece no ser válido",
      "error.phone.required": "El teléfono es obligatorio",
      "error.signin.incorrectOTP":
        "Incorrecto. Verifique dos veces el código de su correo electrónico e ingréselo nuevamente.",
      "errorAlert.default.header": "Ups!",
      "errorAlert.default.message":
        "Nos encontramos con un error de nuestro lado. ¡Lo lamentamos! Si sucede varias veces, no dude en <a href='mailto:support@otuvy.com'>Ponerse en Contacto con el Soporte</a>.",
      "errorAlert.generic.header.error": "Error",
      "errorAlert.generic.header.errors": "Error(es)",
      "errorAlert.generic.contactSupport":
        "Disculpe las molestias. Se ha producido un error. Para resolver este error, por favor <a href=\"mailto:support@otuvy.com\">Póngase en Contacto con el Soporte</a>.",
      "errorAlert.generic.happensAgainContactSupport":
        "Si sucede varias veces, no dude en <a href=\"mailto:support@otuvy.com\">Ponerse en Contacto con el Soporte</a>.",
      "errorAlert.generic.tryAgain":
        "Disculpe, ha ocurrido un error de nuestro lado. Espere unos momentos y luego intente nuevamente.",
      "errorAlert.import.table.duplicates.email.header":
        "Correos electrónicos duplicados en sus datos",
      "errorAlert.import.table.duplicates.email.message1":
        "Los siguientes correos electrónicos están duplicados en sus datos:",
      "errorAlert.import.table.duplicates.email.message2":
        "Corrija (o elimine) los duplicados y vuelva a intentarlo.",
      "errorAlert.import.table.inUse.email.header": "Correos electrónicos ya existentes",
      "errorAlert.import.table.inUse.email.message1":
        "Los siguientes empleados ya están trabajando para otra organización:",
      "errorAlert.import.table.inUse.email.message2":
        "Pídales que soliciten ser eliminados de la otra organización primero, o use un correo electrónico diferente para ellos.",
      "errorAlert.import.table.fixThem":
        "Corrija los errores (generalmente resaltados en rojo), luego intente enviarlo nuevamente.",

      "errorPage": "Hola, soy la Página de Error.",
      "errorPage.backIntoIt": "Retomemos.",
      "errorPage.sorry":
        "Lo sentimos, tuvimos un contratiempo de nuestra parte. No se preocupe, su trabajo debería guardarse automáticamente.",

      "feedback.list.completed": "¡Buen trabajo! Ha completado la lista.",
      "feedback.list.import.success": "{{count}} listas cargadas exitosamente!",
      "feedback.person.added":
        "¡Usuario agregado! Verifique su factura para actualizaciones.<br/><br/>Se ha enviado una invitación a su correo electrónico.",
      "feedback.person.deleted":
        "Usuario eliminado. Su factura se reducirá en consecuencia.",
      "feedback.person.edited": "Cambios Guardados",
      "feedback.person.imported.acknowledge": "Ver Personas",

      "feedback.person.imported.created.message_one":
        "¡Un usuario agregado/actualizado! Verifique su factura para actualizaciones.<br/><br/>Se ha enviado una invitación a su correo electrónico.",
      "feedback.person.imported.created.message_other":
        "¡{{count}} usuarios agregados/actualizados! Verifique su factura para actualizaciones.<br/><br/>Se han enviado invitaciones a sus correos electrónicos.",

      "feedback.person.imported.edited.message_one": "Se editó un usuario.",
      "feedback.person.imported.edited.message_other":
        "Se editaron {{count}} usuarios.",

      "feedback.person.imported.reactivated.message_one": "Se reactivó un usuario.",
      "feedback.person.imported.reactivated.message_other":
        "Se reactivaron {{count}} usuarios.",

      "feedback.person.welcomeEmailsResent_one": "¡{{count}} Correo Enviado!",
      "feedback.person.welcomeEmailsResent_other": "¡{{count}} Correos Enviados!",
      "feedback.success.withFeeling": "¡Éxito!",
      "feedback.task.completed": "¡Buen trabajo! Tarea completada: \"{{taskName}}\"",

      "grid.column.header.user.email": "Correo electrónico",
      "grid.column.header.user.firstName": "Nombre",
      "grid.column.header.user.language": "Idioma",
      "grid.column.header.user.lastName": "Apellido",

      "home": "Inicio",
      "info.amountSelected": "{{count}} seleccionados",

      "label.list.list": "Lista",
      "label.list.owner": "Propietario",
      "label.selectAllCompletedTasks": "Seleccionar todas las tareas completadas",
      "label.selectAllTasks": "Seleccionar todas las tareas",
      "label.task.instructions": "Instrucciones",
      "label.task.name": "Tarea",
      "label.task.taskName": "Tarea",
      "label.task.notes": "Notas",

      "label.usage.admins.requiredAmount": "Admins (se requiere 1)",
      "label.usage.employees": "Empleados",
      "label.usage.lists": "Listas",
      "label.usage.storage": "Espacio de Almacenamiento",
      "label.usage.tasks": "Tareas",
      "label.usage.totalUsers": "Usuarios Totales",

      "label.user.actions": "Acciones",
      "label.user.email": "Correo electrónico",
      "label.user.firstName": "Nombre",
      "label.user.inactive": "Inactivo",
      "label.user.language": "Idioma",
      "label.user.lastName": "Apellido",
      "label.user.role": "Rol",

      "language": "Idioma",
      "language.name.en": "Inglés",
      "language.name.es": "Español",
      "language.name.fr": "Francés",
      "language.name.pt": "Portugués",

      "listName": "Nombre de la Lista",
      "list.rename": "Cambiar Nombre",
      "lists": "Listas",
      "loading": "Cargando...",
      "loading.message.user.import": "Importando sus usuarios.",
      "loading.message.list.import": "Creando sus listas...",

      "logout": "Cerrar sesión",

      "navigation.add": "Agregar",
      "navigation.import": "Importar",
      "navigation.view": "Ver",

      "newCodeRequest": "Enviarme un nuevo código",
      "newVerificationCodeSent.header": "Hemos enviado el código.",
      "newVerificationCodeSent.message":
        "Revise su bandeja de entrada para obtener el nuevo código. Es posible que haya llegado a su carpeta de correo no deseado. A veces, pasa eso.",

      "offlineNoticeHeader": "Sin internet :(",
      "offlineNoticeMessage":
        "Deberíamos reconectarnos pronto. Para saber si no es solo nuestra aplicación, puede... <br/><br/><ul><li>Verificar la configuración de su teléfono</li><li>Verificar otras aplicaciones</li><li>Verificar la intensidad de la señal wifi</li></ul><br/>¡Y no se preocupe, guardamos su trabajo a medida que avanzamos!",

      "otpCode": "Código",
      "otpPrompt":
        "Le enviaremos un código a su correo electrónico para que pueda comenzar a usar Otuvy Frontline",
      "otpSent":
        "Hemos enviado un código de verificación a su correo electrónico. Ingréselo a continuación.",

      "phone": "Teléfono",

      "page.listImport.dropzone.message":
        "Arrastre su archivo de Excel aquí o haga clic para subir uno.",
      "page.listImport.dropzone.message2": "(.XLS o .XLSX solamente)",
      "page.listImport.dialog.error.fileType.header":
        "Error: solo archivos .XLS o .XLSX",
      "page.listImport.dialog.error.fileType.message":
        "<p>Para subir correctamente, sus datos deben estar en formato de archivo Excel (.XLS o .XLSX).</p><p>Si no tiene Excel, intente copiar sus datos en una hoja de Google y luego descárguela como archivo Excel: Archivo > Descargar > Microsoft Excel (.xlsx)</p><p>Si eso no funciona y todavía tiene preguntas, no dude en comunicarse con el soporte en <a href='mailto:support@otuvy.com'>support@otuvy.com</a> o al <a href='tel:8018743443'>801.874.3443</a>.</p>",

      "page.listImport.panel.instructions.header": "Preparando sus Listas para Importar",
      "page.listImport.panel.instructions.instruction1":
        "1. <strong>Descargue</strong> la Plantilla.",
      "page.listImport.panel.instructions.instruction1.sub":
        "<strong>¿Sabía?</strong> ¡Puede exportar una hoja de Google como archivo Excel!",
      "page.listImport.panel.instructions.instruction2":
        "2. <strong>Copie/pegue</strong> sus datos en ella y guarde el archivo.",
      "page.listImport.panel.instructions.instruction2.sub1.description":
        "El nombre de la lista de tareas (Consejo: los clientes pueden ver esto).",
      "page.listImport.panel.instructions.instruction2.sub1.label": "Nombre de Lista",
      "page.listImport.panel.instructions.instruction2.sub2.description":
        "Dirección de correo electrónico: la persona responsable de la lista.",
      "page.listImport.panel.instructions.instruction2.sub2.label": "Propietario de la Lista",
      "page.listImport.panel.instructions.instruction2.sub3.description":
        "Direcciones de correo electrónico: otras personas que deberían ver la lista.",
      "page.listImport.panel.instructions.instruction2.sub3.label":
        "Compartir Lista con",
      "page.listImport.panel.instructions.instruction2.sub4.description":
        "Ingrese una tarea por fila, hacia abajo.",
      "page.listImport.panel.instructions.instruction2.sub4.label": "Tareas",
      "page.listImport.panel.instructions.instruction3":
        "3. <strong>Cargue</strong> el archivo guardado arriba.",
      "page.listImport.panel.validation.header":
        "Sus datos de importación requieren atención.",
      "page.listImport.panel.validation.instruction1":
        "1. Corrija los siguientes errores en su archivo de Excel:",
      "page.listImport.panel.validation.instruction2":
        "2. <strong>Cargue</strong> el archivo guardado (con las correcciones que acaba de hacer) a continuación.",
      "page.listImport.panel.validation.message":
        "Sus listas no se importaron debido a errores que deben corregirse en sus datos.",
      "page.listImport.panel.validation.table.header.columnName": "Columna",
      "page.listImport.panel.validation.table.header.email": "Correo electrónico incorrecto",
      "page.listImport.panel.validation.table.header.errorMessage": "Error",
      "page.listImport.panel.validation.table.header.listName": "Nombre de Lista",

      "picturesAbbreviated": "Fotos",
      "placeholder.changeOwner": "Cambiar propietario a:",
      "placeholder.email": "maria.sanchez@example.com",
      "placeholder.firstName": "María",
      "placeholder.generic": "Tocar para ingresar texto",
      "placeholder.language": "Seleccionar idioma",
      "placeholder.lastName": "Sánchez",
      "placeholder.name": "María Sánchez",
      "placeholder.otp": "Su código de verificación",
      "placeholder.selectOne": "Seleccionar uno...",

      "product.version": "v.",
      "requestToWait": "Espere...",
      "resendingInvite": "Reenviando invitación...",
      "select": "Seleccionar",
      "selectAll": "Seleccionar Todo",
      "sendCode": "Enviar Código de Verificación",
      "settings": "Ajustes",
      "status": "Estado",
      "submit": "Enviar",
      "tasks": "Tareas",

      "title.billing": "Facturación",
      "title.lists": "Listas",
      "title.people": "Personas",
      "title.settings": "Ajustes",
      "title.timeTracking": "Seguimiento de Tiempo",
      "title.usage": "Uso",
      "title.integrations.qm": "Integración Otuvy QM",
      "title.integrations.qm.settings": "Ajustes",
      "title.integrations.qm.locations": "Ubicaciones",

      "undefinedList.name": "Lista Indefinida",
      "unit.gigabyte": "GB",
      "unit.unlimited": "Ilimitado",
      "wifiOnly": "Usar Solo Wifi",
      "wifiOnlyHint":
        "Si desea que la aplicación use los datos de su teléfono, deje esta opción desactivada. Si desea conservar sus datos móviles, actívela."
    }
  },

  fr: {
    translation: {
      "addFirstList": "Touchez le \"+\" <br /> pour ajouter une liste",
      "addFirstTask": "Touchez le \"+\" <br /> pour ajouter une tâche",
      "addList": "Ajouter une liste",
      "addTask": "Ajouter une tâche",
      "banner.governor.pastDue.accountLocked":
        "Désolé, les frais de votre entreprise sont impayés depuis {{count}} jours, donc <b>votre compte est verrouillé</b>. Les utilisateurs ne peuvent plus se connecter à l’application Otuvy. Veuillez mettre à jour la carte enregistrée pour déverrouiller votre compte.",
      "banner.governor.pastDue.limitExceeded_zero":
        "Votre compte est en retard de paiement. Veuillez mettre à jour vos informations de paiement ou votre accès sera limité le {{date}}.",
      "banner.governor.pastDue.limitExceeded_one":
        "Votre compte a un jour de retard. Veuillez mettre à jour vos informations de paiement ou votre accès sera limité le {{date}}.",
      "banner.governor.pastDue.limitExceeded_other":
        "Votre compte a {{count}} jours de retard. Veuillez mettre à jour vos informations de paiement ou votre accès sera limité le {{date}}.",
      "banner.governor.pastDue.writeAccessRestricted":
        "Désolé, les frais de votre entreprise sont impayés depuis {{count}} jours, donc <b>l’accès a été restreint</b> pour vous et vos employés. Veuillez mettre à jour la carte enregistrée pour éviter que votre compte ne soit verrouillé.",
      "button.acknowledge": "OK",
      "button.cancel": "Annuler",
      "button.clear": "Effacer/Réinitialiser",
      "button.delete": "Supprimer",
      "button.download.template": "Télécharger le modèle d’importation (.xls)",
      "button.edit": "Modifier",
      "button.paymentInfo.update": "Mettre à jour les informations de paiement",
      "button.resendInvite": "Renvoyer l’invitation",
      "button.user.activate": "Activer",
      "button.user.add_zero": "Ajouter",
      "button.user.add_one": "Ajouter {{count}} utilisateur",
      "button.user.add_other": "Ajouter {{count}} utilisateurs",
      "button.user.deactivate": "Désactiver",
      "cancel": "Annuler",
      "complete": "Terminer",
      "completed": "Terminé",
      "completeTask": "Terminer la tâche",
      "completeTasks": "Terminer les tâches",
      "completeList": "Terminer la liste",
      "confirmation.delete.image.confirm": "Supprimer l’image",
      "confirmation.delete.image.header": "Supprimer l’image",
      "confirmation.delete.image.message":
        "Vous ne pourrez pas récupérer cette image après sa suppression.",
      "confirmation.delete.list.confirm": "Supprimer la liste",
      "confirmation.delete.list.confirm_one": "Supprimer la liste",
      "confirmation.delete.list.confirm_other": "Supprimer les listes",
      "confirmation.delete.list.header": "Supprimer cette liste ?",
      "confirmation.delete.list.header_one": "Supprimer cette liste ?",
      "confirmation.delete.list.header_other": "Supprimer ces listes ?",
      "confirmation.delete.list.message":
        "Toutes ses tâches (et leurs photos, notes, etc.) seront également supprimées.",
      "confirmation.delete.list.message_one":
        "Toutes ses tâches (et leurs photos, notes, etc.) seront également supprimées.",
      "confirmation.delete.list.message_other":
        "Toutes leurs tâches (et leurs photos, notes, etc.) seront également supprimées.",
      "confirmation.delete.person.confirm1": "Oui, transférer leurs listes à moi",
      "confirmation.delete.person.confirm2": "NON, supprimer leurs listes",
      "confirmation.delete.person.header": "Supprimer l’utilisateur",
      "confirmation.delete.person.message":
        "Souhaitez-vous conserver les listes de cet utilisateur et les transférer à vous-même ?<br/><br/>(Sinon, les listes seront supprimées.)<br/><br/>(Remarque : vous pouvez d’abord modifier les propriétaires des listes, sur la page Listes.)",
      "confirmation.delete.task.confirm": "Supprimer la tâche",
      "confirmation.delete.task.header": "Supprimer la tâche",
      "confirmation.delete.task.message":
        "Êtes-vous sûr ? (Toutes les photos, notes, etc. seront également supprimées.)",
      "confirmation.delete.task.generic.confirm": "Supprimer",
      "confirmation.delete.task.generic.header": "Confirmer la suppression",
      "confirmation.delete.task.generic.message":
        "Êtes-vous sûr ? (Toutes les photos, notes, etc. seront également supprimées.)",
      "contactSupport": "Contacter le support",
      "darkMode": "Mode sombre",
      "delete": "Supprimer",
      "deleteTasks": "Supprimer les tâches",
      "deselectAll": "Tout désélectionner",
      "done": "Terminé",
      "duplicate": "Dupliquer",
      "duplicateTask": "Dupliquer la tâche",
      "duplicateTasks": "Dupliquer les tâches",
      "duplicateList": "Dupliquer la liste",
      "edit": "Modifier",
      "email": "Email",
      "enum.status.active": "Actif",
      "enum.status.inactive": "Inactif",
      "enum.userRole.admin": "Admin",
      "enum.userRole.employee": "Employé",
      "enum.userRole.super_admin": "Super-Admin",
      "error.email.duplicate": "Les adresses email doivent être uniques",
      "error.email.invalid": "Une adresse email valide est requise",
      "error.generic.required": "{{field}} est requis",
      "error.language.unsupported":
        "Seuls l’anglais, l’espagnol, le français et le portugais sont actuellement pris en charge",
      "error.list.listName.required": "Nom de liste manquant",
      "error.list.listOwner.noMatchingEmail":
        "L’e-mail du « Propriétaire de la Liste » ne correspond à aucun utilisateur",
      "error.list.listOwner.required": "Pas de Propriétaire de la Liste",
      "error.list.sharedWith.noMatchingEmail":
        "L’e-mail de « Partager la Liste avec » ne correspond à aucun utilisateur",
      "error.name.required": "Le nom est requis",
      "error.phone.invalid": "Le téléphone semble invalide",
      "error.phone.required": "Le téléphone est requis",
      "error.signin.incorrectOTP":
        "Incorrect. Veuillez vérifier le code de votre email et réessayer.",
      "errorAlert.default.header": "Oups !",
      "errorAlert.default.message":
        "Nous avons rencontré une erreur de notre côté. Désolé ! Si cela se produit plusieurs fois, n’hésitez pas à <a href='mailto:support@otuvy.com'>contacter le support</a>.",
      "errorAlert.generic.header.error": "Erreur",
      "errorAlert.generic.header.errors": "Erreur(s)",
      "errorAlert.generic.contactSupport":
        "Nous nous excusons pour le désagrément. Une erreur est survenue. Pour la résoudre, veuillez <a href=\"mailto:support@otuvy.com\">contacter le support</a>.",
      "errorAlert.generic.happensAgainContactSupport":
        "Si cela se produit plusieurs fois, n’hésitez pas à <a href=\"mailto:support@otuvy.com\">contacter le support</a>.",
      "errorAlert.generic.tryAgain":
        "Nous nous excusons, une erreur est survenue de notre côté. Veuillez patienter quelques instants, puis réessayez.",
      "errorAlert.import.table.duplicates.email.header":
        "Doublons d’emails dans vos données",
      "errorAlert.import.table.duplicates.email.message1":
        "Les emails suivants sont dupliqués dans vos données :",
      "errorAlert.import.table.duplicates.email.message2":
        "Veuillez corriger (ou supprimer) les doublons et réessayer.",
      "errorAlert.import.table.inUse.email.header":
        "Les emails existent déjà",
      "errorAlert.import.table.inUse.email.message1":
        "Les employés suivants travaillent déjà pour une autre organisation :",
      "errorAlert.import.table.inUse.email.message2":
        "Veuillez leur demander d’être retirés de l’autre organisation d’abord, ou utilisez un autre email pour eux.",
      "errorAlert.import.table.fixThem":
        "Veuillez corriger les erreurs (généralement surlignées en rouge), puis réessayer de soumettre.",

      "errorPage": "Bonjour, je suis la page d’erreur.",
      "errorPage.backIntoIt": "Reprenons où nous en étions.",
      "errorPage.sorry":
        "Désolé, nous avons rencontré un problème de notre côté. Ne vous inquiétez pas, votre travail devrait être sauvegardé automatiquement.",

      "feedback.list.completed": "Beau travail ! Vous avez terminé la liste.",
      "feedback.list.import.success":
        "{{count}} listes téléchargées avec succès !",
      "feedback.person.added":
        "Utilisateur ajouté ! Consultez votre facture pour les mises à jour.<br/><br/>Une invitation a été envoyée à son email.",
      "feedback.person.deleted":
        "Utilisateur supprimé. Votre facture sera réduite en conséquence.",
      "feedback.person.edited": "Modifications enregistrées",
      "feedback.person.imported.acknowledge": "Voir les personnes",

      "feedback.person.imported.created.message_one":
        "Un utilisateur ajouté/mis à jour ! Consultez votre facture pour les mises à jour.<br/><br/>Une invitation a été envoyée à son email.",
      "feedback.person.imported.created.message_other":
        "{{count}} utilisateurs ajoutés/mis à jour ! Consultez votre facture pour les mises à jour.<br/><br/>Des invitations ont été envoyées à leurs emails.",

      "feedback.person.imported.edited.message_one": "Un utilisateur a été modifié.",
      "feedback.person.imported.edited.message_other":
        "{{count}} utilisateurs ont été modifiés.",

      "feedback.person.imported.reactivated.message_one":
        "Un utilisateur a été réactivé.",
      "feedback.person.imported.reactivated.message_other":
        "{{count}} utilisateurs ont été réactivés.",

      "feedback.person.welcomeEmailsResent_one": "{{count}} email envoyé !",
      "feedback.person.welcomeEmailsResent_other": "{{count}} emails envoyés !",
      "feedback.success.withFeeling": "Succès !",
      "feedback.task.completed": "Beau travail ! Tâche terminée : « {{taskName}} »",

      "grid.column.header.user.email": "Email",
      "grid.column.header.user.firstName": "Prénom",
      "grid.column.header.user.language": "Langue",
      "grid.column.header.user.lastName": "Nom",

      "home": "Accueil",
      "info.amountSelected": "{{count}} sélectionné(s)",

      "label.list.list": "Liste",
      "label.list.owner": "Propriétaire",
      "label.selectAllCompletedTasks": "Sélectionner toutes les tâches terminées",
      "label.selectAllTasks": "Sélectionner toutes les tâches",
      "label.task.instructions": "Instructions",
      "label.task.name": "Tâche",
      "label.task.taskName": "Tâche",
      "label.task.notes": "Notes",

      "label.usage.admins.requiredAmount": "Admins (1 requis)",
      "label.usage.employees": "Employés",
      "label.usage.lists": "Listes",
      "label.usage.storage": "Espace de stockage",
      "label.usage.tasks": "Tâches",
      "label.usage.totalUsers": "Nombre total d’utilisateurs",

      "label.user.actions": "Actions",
      "label.user.email": "Email",
      "label.user.firstName": "Prénom",
      "label.user.inactive": "Inactif",
      "label.user.language": "Langue",
      "label.user.lastName": "Nom",
      "label.user.role": "Rôle",

      "language": "Langue",
      "language.name.en": "Anglais",
      "language.name.es": "Espagnol",
      "language.name.fr": "Français",
      "language.name.pt": "Portugais",

      "listName": "Nom de la Liste",
      "list.rename": "Renommer la liste",
      "lists": "Listes",
      "loading": "Chargement...",
      "loading.message.user.import": "Importation de vos utilisateurs.",
      "loading.message.list.import": "Création de vos listes...",

      "logout": "Se déconnecter",

      "navigation.add": "Ajouter",
      "navigation.import": "Importer",
      "navigation.view": "Voir",

      "newCodeRequest": "Envoyez-moi un nouveau code",
      "newVerificationCodeSent.header": "Nous avons envoyé le code.",
      "newVerificationCodeSent.message":
        "Veuillez vérifier votre boîte mail pour le nouveau code. Il se peut qu’il soit arrivé dans vos courriers indésirables. Parfois, ça arrive.",

      "offlineNoticeHeader": "Pas d’internet :(",
      "offlineNoticeMessage":
        "Nous devrions nous reconnecter bientôt. Pour savoir si ce n’est pas seulement notre application, vous pouvez… <br/><br/><ul><li>Vérifier les paramètres de votre téléphone</li><li>Vérifier d’autres applications</li><li>Vérifier la force du signal Wi-Fi</li></ul><br/>Et ne vous inquiétez pas, nous sauvegardons votre travail au fur et à mesure !",

      "otpCode": "Code",
      "otpPrompt":
        "Nous enverrons un code à votre email pour que vous puissiez commencer à utiliser Otuvy Frontline",
      "otpSent":
        "Nous avons envoyé un code de vérification à votre email. Veuillez le saisir ci-dessous.",

      "phone": "Téléphone",

      "page.listImport.dropzone.message":
        "Glissez votre fichier Excel ici ou cliquez pour en télécharger un.",
      "page.listImport.dropzone.message2": "(.XLS ou .XLSX uniquement)",
      "page.listImport.dialog.error.fileType.header":
        "Erreur : .XLS ou .XLSX uniquement",
      "page.listImport.dialog.error.fileType.message":
        "<p>Pour téléverser correctement, vos données doivent être au format de fichier Excel (.XLS ou .XLSX).</p><p>Si vous n’avez pas Excel, essayez de copier vos données dans une feuille Google, puis de la télécharger au format Excel : Fichier > Télécharger > Microsoft Excel (.xlsx)</p><p>Si cela ne fonctionne pas et que vous avez toujours des questions, n’hésitez pas à contacter le support à <a href='mailto:support@otuvy.com'>support@otuvy.com</a> ou au <a href='tel:8018743443'>801.874.3443</a>.</p>",

      "page.listImport.panel.instructions.header": "Préparation de vos listes pour l’importation",
      "page.listImport.panel.instructions.instruction1":
        "1. <strong>Téléchargez</strong> le modèle.",
      "page.listImport.panel.instructions.instruction1.sub":
        "<strong>Le saviez-vous ?</strong> Vous pouvez exporter une feuille Google sous forme de fichier Excel !",
      "page.listImport.panel.instructions.instruction2":
        "2. <strong>Copiez/collez</strong> vos données, puis enregistrez le fichier.",
      "page.listImport.panel.instructions.instruction2.sub1.description":
        "Le nom de la liste de tâches (Conseil : les clients peuvent la voir).",
      "page.listImport.panel.instructions.instruction2.sub1.label":
        "Nom de la liste",
      "page.listImport.panel.instructions.instruction2.sub2.description":
        "Adresse email – la personne responsable de la liste.",
      "page.listImport.panel.instructions.instruction2.sub2.label":
        "Propriétaire de la liste",
      "page.listImport.panel.instructions.instruction2.sub3.description":
        "Adresses email – autres personnes qui devraient voir la liste.",
      "page.listImport.panel.instructions.instruction2.sub3.label":
        "Partager la liste avec",
      "page.listImport.panel.instructions.instruction2.sub4.description":
        "Saisissez une tâche par ligne, en descendant.",
      "page.listImport.panel.instructions.instruction2.sub4.label": "Tâches",
      "page.listImport.panel.instructions.instruction3":
        "3. <strong>Téléversez</strong> le fichier enregistré ci-dessus.",
      "page.listImport.panel.validation.header":
        "Vos données d’importation nécessitent une attention particulière.",
      "page.listImport.panel.validation.instruction1":
        "1. Corrigez les erreurs suivantes dans votre fichier Excel :",
      "page.listImport.panel.validation.instruction2":
        "2. <strong>Téléversez</strong> le fichier enregistré (avec les corrections que vous venez de faire) ci-dessous.",
      "page.listImport.panel.validation.message":
        "Vos listes n’ont pas été importées en raison d’erreurs qui doivent être corrigées dans vos données.",
      "page.listImport.panel.validation.table.header.columnName": "Colonne",
      "page.listImport.panel.validation.table.header.email": "Email incorrect",
      "page.listImport.panel.validation.table.header.errorMessage": "Erreur",
      "page.listImport.panel.validation.table.header.listName": "Nom de la liste",

      "picturesAbbreviated": "Photos",
      "placeholder.changeOwner": "Changer le propriétaire en :",
      "placeholder.email": "jean.dupont@example.com",
      "placeholder.firstName": "Jean",
      "placeholder.generic": "Touchez pour saisir le texte",
      "placeholder.language": "Sélectionnez la langue",
      "placeholder.lastName": "Dupont",
      "placeholder.name": "Jean Dupont",
      "placeholder.otp": "Votre code de vérification",
      "placeholder.selectOne": "Sélectionnez...",

      "product.version": "v.",
      "requestToWait": "Veuillez patienter...",
      "resendingInvite": "Renvoi de l’invitation...",
      "select": "Sélectionner",
      "selectAll": "Tout sélectionner",
      "sendCode": "Envoyer le code de vérification",
      "settings": "Paramètres",
      "status": "Statut",
      "submit": "Valider",
      "tasks": "Tâches",
      "title.billing": "Facturation",
      "title.lists": "Listes",
      "title.people": "Personnes",
      "title.settings": "Paramètres",
      "title.timeTracking": "Suivi du temps",
      "title.usage": "Utilisation",
      "title.integrations.qm": "Intégration Otuvy QM",
      "title.integrations.qm.settings": "Paramètres",
      "title.integrations.qm.locations": "Emplacements",
      "undefinedList.name": "Liste non définie",
      "unit.gigabyte": "Go",
      "unit.unlimited": "Illimité",
      "wifiOnly": "Utiliser uniquement le Wi-Fi",
      "wifiOnlyHint":
        "Si vous souhaitez que l’application utilise vos données mobiles, laissez cette option désactivée. Pour préserver vos données, activez-la."
    }
  },

  pt: {
    translation: {
      "addFirstList": "Toque em \"+\" <br /> para adicionar uma lista",
      "addFirstTask": "Toque em \"+\" <br /> para adicionar uma tarefa",
      "addList": "Adicionar Lista",
      "addTask": "Adicionar Tarefa",
      "banner.governor.pastDue.accountLocked":
        "Desculpe, as taxas da sua empresa estão atrasadas há {{count}} dias, então <b>sua conta está bloqueada</b>. Os usuários não podem mais fazer login no aplicativo Otuvy. Atualize o cartão registrado para desbloquear sua conta.",
      "banner.governor.pastDue.limitExceeded_zero":
        "Sua conta está em atraso. Atualize suas informações de pagamento ou seu acesso será limitado em {{date}}.",
      "banner.governor.pastDue.limitExceeded_one":
        "Sua conta está atrasada há {{count}} dia. Atualize suas informações de pagamento ou seu acesso será limitado em {{date}}.",
      "banner.governor.pastDue.limitExceeded_other":
        "Sua conta está atrasada há {{count}} dias. Atualize suas informações de pagamento ou seu acesso será limitado em {{date}}.",
      "banner.governor.pastDue.writeAccessRestricted":
        "Desculpe, as taxas da sua empresa estão atrasadas há {{count}} dias, portanto <b>o acesso foi limitado</b> para você e seus funcionários. Atualize o cartão registrado para evitar que sua conta seja bloqueada.",
      "button.acknowledge": "OK",
      "button.cancel": "Cancelar",
      "button.clear": "Limpar/Redefinir",
      "button.delete": "Excluir",
      "button.download.template": "Baixar Modelo de Importação (.xls)",
      "button.edit": "Editar",
      "button.paymentInfo.update": "Atualizar Informações de Pagamento",
      "button.resendInvite": "Reenviar Convite",
      "button.user.activate": "Ativar",
      "button.user.add_zero": "Adicionar",
      "button.user.add_one": "Adicionar {{count}} Usuário",
      "button.user.add_other": "Adicionar {{count}} Usuários",
      "button.user.deactivate": "Desativar",
      "cancel": "Cancelar",
      "complete": "Concluir",
      "completed": "Concluído",
      "completeTask": "Concluir Tarefa",
      "completeTasks": "Concluir Tarefas",
      "completeList": "Concluir Lista",
      "confirmation.delete.image.confirm": "Excluir imagem",
      "confirmation.delete.image.header": "Excluir Imagem",
      "confirmation.delete.image.message":
        "Você não poderá recuperar esta imagem após a exclusão.",
      "confirmation.delete.list.confirm": "Excluir a lista",
      "confirmation.delete.list.confirm_one": "Excluir a lista",
      "confirmation.delete.list.confirm_other": "Excluir as listas",
      "confirmation.delete.list.header": "Excluir esta lista?",
      "confirmation.delete.list.header_one": "Excluir esta lista?",
      "confirmation.delete.list.header_other": "Excluir estas listas?",
      "confirmation.delete.list.message":
        "Todas as suas tarefas (e suas fotos, notas, etc.) também serão excluídas.",
      "confirmation.delete.list.message_one":
        "Todas as suas tarefas (e suas fotos, notas, etc.) também serão excluídas.",
      "confirmation.delete.list.message_other":
        "Todas as suas tarefas (e suas fotos, notas, etc.) também serão excluídas.",
      "confirmation.delete.person.confirm1":
        "Sim, transferir as listas dele(a) para mim",
      "confirmation.delete.person.confirm2": "NÃO, excluir as listas dele(a)",
      "confirmation.delete.person.header": "Excluir Usuário",
      "confirmation.delete.person.message":
        "Você gostaria de manter as listas deste usuário e transferi-las para você?<br/><br/>(Se não, as listas serão excluídas.)<br/><br/>(Observação: você pode alterar os proprietários das listas primeiro, na página Listas.)",
      "confirmation.delete.task.confirm": "Excluir tarefa",
      "confirmation.delete.task.header": "Excluir Tarefa",
      "confirmation.delete.task.message":
        "Você tem certeza? (Todas as fotos, notas, etc. também serão excluídas.)",
      "confirmation.delete.task.generic.confirm": "Excluir",
      "confirmation.delete.task.generic.header": "Confirmar Exclusão",
      "confirmation.delete.task.generic.message":
        "Você tem certeza? (Todas as fotos, notas, etc. também serão excluídas.)",
      "contactSupport": "Entrar em Contato com o Suporte",
      "darkMode": "Modo Escuro",
      "delete": "Excluir",
      "deleteTasks": "Excluir Tarefas",
      "deselectAll": "Desmarcar Tudo",
      "done": "Concluído",
      "duplicate": "Duplicar",
      "duplicateTask": "Duplicar Tarefa",
      "duplicateTasks": "Duplicar Tarefas",
      "duplicateList": "Duplicar Lista",
      "edit": "Editar",
      "email": "Email",
      "enum.status.active": "Ativo",
      "enum.status.inactive": "Inativo",
      "enum.userRole.admin": "Admin",
      "enum.userRole.employee": "Funcionário",
      "enum.userRole.super_admin": "Super-Admin",
      "error.email.duplicate": "Os endereços de email devem ser exclusivos",
      "error.email.invalid": "É necessário um endereço de email válido",
      "error.generic.required": "{{field}} é obrigatório",
      "error.language.unsupported":
        "Atualmente, apenas inglês, espanhol, francês e português são suportados",
      "error.list.listName.required": "Nome da Lista ausente",
      "error.list.listOwner.noMatchingEmail":
        "O email do \"Proprietário da Lista\" não corresponde a nenhum usuário",
      "error.list.listOwner.required": "Sem Proprietário da Lista",
      "error.list.sharedWith.noMatchingEmail":
        "O email de \"Compartilhar Lista com\" não corresponde a nenhum usuário",
      "error.name.required": "O nome é obrigatório",
      "error.phone.invalid": "O telefone parece ser inválido",
      "error.phone.required": "O telefone é obrigatório",
      "error.signin.incorrectOTP":
        "Incorreto. Verifique o código no seu email e tente novamente.",
      "errorAlert.default.header": "Ops!",
      "errorAlert.default.message":
        "Encontramos um erro do nosso lado. Desculpe! Se isso acontecer várias vezes, fique à vontade para <a href='mailto:support@otuvy.com'>Entrar em Contato com o Suporte</a>.",
      "errorAlert.generic.header.error": "Erro",
      "errorAlert.generic.header.errors": "Erro(s)",
      "errorAlert.generic.contactSupport":
        "Pedimos desculpas pelo inconveniente. Ocorreu um erro. Para resolver este erro, por favor <a href=\"mailto:support@otuvy.com\">Entre em Contato com o Suporte</a>.",
      "errorAlert.generic.happensAgainContactSupport":
        "Se acontecer várias vezes, fique à vontade para <a href=\"mailto:support@otuvy.com\">Entrar em Contato com o Suporte</a>.",
      "errorAlert.generic.tryAgain":
        "Pedimos desculpas, ocorreu um erro do nosso lado. Aguarde alguns instantes e tente novamente.",
      "errorAlert.import.table.duplicates.email.header":
        "Emails duplicados em seus dados",
      "errorAlert.import.table.duplicates.email.message1":
        "Os seguintes emails estão duplicados em seus dados:",
      "errorAlert.import.table.duplicates.email.message2":
        "Corrija (ou remova) os duplicados e tente novamente.",
      "errorAlert.import.table.inUse.email.header":
        "Emails já existem",
      "errorAlert.import.table.inUse.email.message1":
        "Os seguintes funcionários já estão trabalhando para outra organização:",
      "errorAlert.import.table.inUse.email.message2":
        "Peça a eles que sejam removidos da outra organização primeiro, ou use um email diferente para eles.",
      "errorAlert.import.table.fixThem":
        "Corrija quaisquer erros (geralmente destacados em vermelho) e tente enviar novamente.",
      "errorPage": "Oi, eu sou a Página de Erro.",
      "errorPage.backIntoIt": "Vamos voltar ao que estávamos fazendo.",
      "errorPage.sorry":
        "Desculpe, tivemos um problema do nosso lado. Não se preocupe, seu trabalho deve ser salvo automaticamente.",
      "feedback.list.completed":
        "Bom trabalho! Você concluiu a lista.",
      "feedback.list.import.success":
        "{{count}} listas carregadas com sucesso!",
      "feedback.person.added":
        "Usuário adicionado! Verifique sua fatura para atualizações.<br/><br/>Foi enviado um convite para o email dele(a).",
      "feedback.person.deleted":
        "Usuário removido. Sua conta será ajustada em conformidade.",
      "feedback.person.edited": "Alterações salvas",
      "feedback.person.imported.acknowledge": "Visualizar Pessoas",
      "feedback.person.imported.created.message_one":
        "Um usuário adicionado/atualizado! Verifique sua fatura para atualizações.<br/><br/>Foi enviado um convite para o email dele(a).",
      "feedback.person.imported.created.message_other":
        "{{count}} usuários adicionados/atualizados! Verifique sua fatura para atualizações.<br/><br/>Convites foram enviados para seus emails.",
      "feedback.person.imported.edited.message_one":
        "Um usuário foi editado.",
      "feedback.person.imported.edited.message_other":
        "{{count}} usuários foram editados",
      "feedback.person.imported.reactivated.message_one":
        "Um usuário foi reativado.",
      "feedback.person.imported.reactivated.message_other":
        "{{count}} usuários foram reativados",
      "feedback.person.welcomeEmailsResent_one":
        "{{count}} email enviado!",
      "feedback.person.welcomeEmailsResent_other":
        "{{count}} emails enviados!",
      "feedback.success.withFeeling": "Sucesso!",
      "feedback.task.completed":
        "Bom trabalho! Tarefa concluída: \"{{taskName}}\"",
      "grid.column.header.user.email": "Email",
      "grid.column.header.user.firstName": "Nome",
      "grid.column.header.user.language": "Idioma",
      "grid.column.header.user.lastName": "Sobrenome",
      "home": "Início",
      "info.amountSelected": "{{count}} selecionado(s)",
      "label.list.list": "Lista",
      "label.list.owner": "Proprietário",
      "label.selectAllCompletedTasks": "Selecionar todas as tarefas concluídas",
      "label.selectAllTasks": "Selecionar todas as tarefas",
      "label.task.instructions": "Instruções",
      "label.task.name": "Tarefa",
      "label.task.taskName": "Tarefa",
      "label.task.notes": "Notas",
      "label.usage.admins.requiredAmount": "Admins (1 requerido)",
      "label.usage.employees": "Funcionários",
      "label.usage.lists": "Listas",
      "label.usage.storage": "Espaço de Armazenamento",
      "label.usage.tasks": "Tarefas",
      "label.usage.totalUsers": "Total de Usuários",
      "label.user.actions": "Ações",
      "label.user.email": "Email",
      "label.user.firstName": "Nome",
      "label.user.inactive": "Inativo",
      "label.user.language": "Idioma",
      "label.user.lastName": "Sobrenome",
      "label.user.role": "Função",
      "language": "Idioma",
      "language.name.en": "Inglês",
      "language.name.es": "Espanhol",
      "language.name.fr": "Francês",
      "language.name.pt": "Português",
      "listName": "Nome da Lista",
      "list.rename": "Renomear Lista",
      "lists": "Listas",
      "loading": "Carregando...",
      "loading.message.user.import": "Importando seus usuários.",
      "loading.message.list.import": "Criando suas listas...",
      "logout": "Sair",
      "navigation.add": "Adicionar",
      "navigation.import": "Importar",
      "navigation.view": "Visualizar",
      "newCodeRequest": "Enviar-me um novo código",
      "newVerificationCodeSent.header": "Enviamos o código.",
      "newVerificationCodeSent.message":
        "Verifique sua caixa de entrada para o novo código. Ele pode ter ido para a pasta de spam. Às vezes isso acontece.",
      "offlineNoticeHeader": "Sem internet :(",
      "offlineNoticeMessage":
        "Devemos nos reconectar em breve. Para verificar se não é apenas nosso aplicativo, você pode... <br/><br/><ul><li>Verificar as configurações do seu telefone</li><li>Verificar outros aplicativos</li><li>Verificar a intensidade do sinal Wi-Fi</li></ul><br/>E não se preocupe, salvamos seu trabalho conforme você avança!",
      "otpCode": "Código",
      "otpPrompt":
        "Enviaremos um código para seu email para que você possa começar a usar o Otuvy Frontline",
      "otpSent":
        "Enviamos um código de verificação para seu email. Por favor, insira-o abaixo.",
      "phone": "Telefone",
      "page.listImport.dropzone.message":
        "Arraste seu arquivo Excel aqui ou clique para fazer upload.",
      "page.listImport.dropzone.message2": "(.XLS ou .XLSX somente)",
      "page.listImport.dialog.error.fileType.header":
        "Erro: apenas arquivos .XLS ou .XLSX",
      "page.listImport.dialog.error.fileType.message":
        "<p>Para fazer upload com sucesso, seus dados devem estar em formato de arquivo Excel (.XLS ou .XLSX).</p><p>Se você não tiver Excel, tente copiar seus dados para uma planilha do Google e depois baixar como arquivo Excel: Arquivo > Fazer download > Microsoft Excel (.xlsx)</p><p>Se isso não funcionar e você ainda tiver dúvidas, fique à vontade para entrar em contato com o suporte em <a href='mailto:support@otuvy.com'>support@otuvy.com</a> ou <a href='tel:8018743443'>801.874.3443</a>.</p>",
      "page.listImport.panel.instructions.header": "Preparando suas Listas para Importação",
      "page.listImport.panel.instructions.instruction1":
        "1. <strong>Baixe</strong> o Modelo.",
      "page.listImport.panel.instructions.instruction1.sub":
        "<strong>Você sabia?</strong> Você pode exportar uma planilha do Google como um arquivo Excel!",
      "page.listImport.panel.instructions.instruction2":
        "2. <strong>Copie/cole</strong> seus dados nele e salve o arquivo.",
      "page.listImport.panel.instructions.instruction2.sub1.description":
        "O nome da lista de tarefas (Dica: os clientes podem ver isso).",
      "page.listImport.panel.instructions.instruction2.sub1.label": "Nome da Lista",
      "page.listImport.panel.instructions.instruction2.sub2.description":
        "Endereço de email - a pessoa responsável pela lista.",
      "page.listImport.panel.instructions.instruction2.sub2.label": "Proprietário da Lista",
      "page.listImport.panel.instructions.instruction2.sub3.description":
        "Endereços de email - outras pessoas que devem ver a lista.",
      "page.listImport.panel.instructions.instruction2.sub3.label": "Compartilhar Lista com",
      "page.listImport.panel.instructions.instruction2.sub4.description":
        "Insira uma tarefa por linha, descendo.",
      "page.listImport.panel.instructions.instruction2.sub4.label": "Tarefas",
      "page.listImport.panel.instructions.instruction3":
        "3. <strong>Carregue</strong> o arquivo salvo acima.",
      "page.listImport.panel.validation.header":
        "Seus dados de importação precisam de atenção.",
      "page.listImport.panel.validation.instruction1":
        "1. Corrija os erros a seguir em seu arquivo Excel:",
      "page.listImport.panel.validation.instruction2":
        "2. <strong>Carregue</strong> o arquivo salvo (com as correções que você acabou de fazer) abaixo.",
      "page.listImport.panel.validation.message":
        "Suas listas não foram importadas devido a erros que precisam ser corrigidos em seus dados.",
      "page.listImport.panel.validation.table.header.columnName": "Coluna",
      "page.listImport.panel.validation.table.header.email": "Email incorreto",
      "page.listImport.panel.validation.table.header.errorMessage": "Erro",
      "page.listImport.panel.validation.table.header.listName": "Nome da Lista",
      "picturesAbbreviated": "Fotos",
      "placeholder.changeOwner": "Mudar Proprietário para:",
      "placeholder.email": "joao.silva@example.com",
      "placeholder.firstName": "João",
      "placeholder.generic": "Toque para inserir texto",
      "placeholder.language": "Selecione o idioma",
      "placeholder.lastName": "Silva",
      "placeholder.name": "João Silva",
      "placeholder.otp": "Seu código de verificação",
      "placeholder.selectOne": "Selecione...",
      "product.version": "v.",
      "requestToWait": "Por favor, aguarde...",
      "resendingInvite": "Reenviando Convite...",
      "select": "Selecionar",
      "selectAll": "Selecionar Tudo",
      "sendCode": "Enviar Código de Verificação",
      "settings": "Configurações",
      "status": "Status",
      "submit": "Enviar",
      "tasks": "Tarefas",
      "title.billing": "Cobrança",
      "title.lists": "Listas",
      "title.people": "Pessoas",
      "title.settings": "Configurações",
      "title.timeTracking": "Controle de Tempo",
      "title.usage": "Uso",
      "title.integrations.qm": "Integração Otuvy QM",
      "title.integrations.qm.settings": "Configurações",
      "title.integrations.qm.locations": "Localizações",
      "undefinedList.name": "Lista Indefinida",
      "unit.gigabyte": "GB",
      "unit.unlimited": "Ilimitado",
      "wifiOnly": "Usar Apenas Wi-Fi",
      "wifiOnlyHint":
        "Se você deseja que o aplicativo use seus dados móveis, deixe esta opção desativada. Se deseja economizar seus dados, ative-a."
    }
  }
};

export default resources;
