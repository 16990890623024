import { useIonViewDidLeave, useIonViewWillEnter } from '@ionic/react';
import { useEffect, useState } from 'react';
import useErrorMessage from '../error/useErrorMessage';

// The trailing comma in the generic type declaration is a hack to make it work in a .tsx file: https://stackoverflow.com/a/56989122/5423329
const useGraphqlQuery = <T,>(query: (() => Promise<T>) | undefined) => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [shouldRefreshData, setRefreshData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const refreshData = () => setRefreshData((prev) => !prev);
  const { handleError } = useErrorMessage();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const result = query ? await query() : undefined;
      setData(result);
    } catch (error) {
      handleError(true);
      console.error('Error fetching data', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [shouldRefreshData]);

  useIonViewWillEnter(() => {
    fetchData();
  }, []);

  useIonViewDidLeave(() => {
    setData(undefined);
  });

  return {
    data,
    isLoading,
    setData, //We expose setData so that we can do local changes without needing to do the full back and forth to the server
    refreshData,
  };
};

export default useGraphqlQuery;
