import './UsageMetricsPanel.css';
import { IonCard, IonCol, IonGrid, IonIcon, IonItem, IonList, IonListHeader, IonRow } from '@ionic/react';
import { hammerOutline, peopleOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { OrganizationMetrics } from '../../usageApi';
import { useRecoilValue } from 'recoil';
import { governorsState } from '../../hooks/useFetchGovernors';

interface UsageMetricsPanelProps {
  metrics: OrganizationMetrics | undefined;
}

const UsageMetricsPanel: React.FC<UsageMetricsPanelProps> = ({ metrics }) => {
  const governors = useRecoilValue(governorsState);

  const { t } = useTranslation();

  const { storageSpaceInMB, numberOfLists, numberOfTasks, numberOfEmployees, numberOfAdmins } = metrics ?? {};
  const { isPaid, maxStorageSpaceInMb } = governors ?? {};

  const storageSpaceInGB = storageSpaceInMB !== undefined ? (storageSpaceInMB / 1024).toFixed(2) : '--';
  const maxStorageSpaceInGB = maxStorageSpaceInMb !== undefined ? (maxStorageSpaceInMb / 1024).toFixed(0) : '--';
  const maxNumberOfLists = t('unit.unlimited');
  const maxNumberOfTasks = t('unit.unlimited');

  const totalUsersDataText = `${
    numberOfEmployees !== undefined && numberOfAdmins !== undefined ? numberOfEmployees + numberOfAdmins : '--'
  }`;
  const adminsDataText = `${numberOfAdmins ?? '--'}`;
  const storageDataText = `${storageSpaceInGB}${isPaid ? ` / ${maxStorageSpaceInGB} ${t('unit.gigabyte')}` : ''}`;
  const listsDataText = `${numberOfLists ?? '--'}${isPaid ? ` / ${maxNumberOfLists}` : ''}`;
  const tasksDataText = `${numberOfTasks ?? '--'}${isPaid ? ` / ${maxNumberOfTasks}` : ''}`;

  return (
    <IonGrid fixed={true}>
      <IonRow className="ion-justify-content-start">
        <IonCol sizeXs="12" sizeMd="8" sizeLg="7" sizeXl="6">
          <IonCard>
            <IonList>
              <IonListHeader>
                <IonIcon icon={peopleOutline} size="large" color="medium" />
              </IonListHeader>
              <IonItem className="font-24" lines="none">
                <div slot="start">{t('label.usage.totalUsers')}</div>
                <div slot="end">{totalUsersDataText}</div>
              </IonItem>
              <IonItem lines="none">
                <div slot="start">{t('label.usage.admins.requiredAmount')}</div>
                <div slot="end">{adminsDataText}</div>
              </IonItem>
            </IonList>
            <IonList>
              <IonListHeader>
                <IonIcon icon={hammerOutline} size="large" color="medium" />
              </IonListHeader>
              <IonItem className="font-24" lines="none">
                <div slot="start">{t('label.usage.storage')}</div>
                <div slot="end">{storageDataText}</div>
              </IonItem>
              <IonItem lines="none">
                <div slot="start">{t('label.usage.lists')}</div>
                <div slot="end">{listsDataText}</div>
              </IonItem>
              <IonItem lines="none">
                <div slot="start">{t('label.usage.tasks')}</div>
                <div slot="end">{tasksDataText}</div>
              </IonItem>
            </IonList>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default UsageMetricsPanel;
