import {
  IonButton,
  IonButtons,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonText,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonModal,
} from '@ionic/react';
import { closeOutline, openOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonPickerSearch from '../../../../components/personPicker/PersonPickerSearch';
import { Person, deactivatePerson } from '../../peopleApi';
import { useRecoilState } from 'recoil';
import { integrationState } from '../../../integrations/qm/useFetchIntegration';
import useToast from '../../../../hooks/useToast';
import { graphql } from '../../../../utils/api/apiUtils';
import { EnvironmentConfig, getFlag } from '../../../../utils/environmentUtils';
import DeletePersonConfirmationModal from './DeletePersonConfirmationModal';
import { getIntegrationInfo } from '../../../integrations/qm/integrationApi';
import useGetPersonPickerSearchOwnerData from '../../../../components/personPicker/useGetPersonPickerOwnerData';

interface NewDeletePersonDialogProps {
  dismissDeleteModal: (shouldDismissAll?: boolean) => void;
  refreshData: () => void;
  person: Person;
}

const NewDeletePersonDialog: React.FC<NewDeletePersonDialogProps> = ({ dismissDeleteModal, refreshData, person }) => {
  const [radioGroupValue, setRadioGroupValue] = useState<string>('delete');
  const [listTransferUserId, setListTransferUserId] = useState<string | undefined>(undefined);
  const [defaultListOwnerTransferUserId, setDefaultListOwnerTransferUserId] = useState<string | undefined>(undefined);

  const [integrationInfo, setIntegrationInfo] = useRecoilState(integrationState);
  const { isConnected, defaultListOwner } = integrationInfo ?? {};

  useEffect(() => {
    getIntegrationInfo().then((integration) => {
      setIntegrationInfo(integration);
    });
  }, []);

  const [presentToast] = useToast();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentDeleteConfirmationModal, dismissDeleteConfirmation] = useIonModal(DeletePersonConfirmationModal, {
    onDeleteConformation: deleteThisUser,
    onDismissConfirmation: dismissDeleteConfirmationModal,
    person: person,
  });
  const { people } = useGetPersonPickerSearchOwnerData();
  const { t } = useTranslation();

  const onListTransferUserIdChange = (e: CustomEvent) => {
    setListTransferUserId(e.detail.value);
  };

  const onDefaultListOwnerTransferUserIdChange = (e: CustomEvent) => {
    setDefaultListOwnerTransferUserId(e.detail.value);
  };

  const disabledDeleteButtonConditionCheck = () => {
    if (isConnected && person.userId === defaultListOwner) {
      if (radioGroupValue === 'transfer' && !listTransferUserId) return true;
      if (!defaultListOwnerTransferUserId) return true;
      if (defaultListOwnerTransferUserId === defaultListOwner) return true;
    }

    if (radioGroupValue === 'transfer') {
      if (!listTransferUserId) return true;
      if (listTransferUserId === person.userId) return true;
    }

    return false;
  };

  async function deleteThisUser() {
    try {
      presentLoading({
        message: t('requestToWait'),
      });

      if (isConnected && person.userId === defaultListOwner) {
        const query = `
      mutation updateQMDefaultListOwner($defaultListOwner: ID!) {
        updateQMDefaultListOwner(defaultListOwner: $defaultListOwner)
      }`;

        const variables = {
          defaultListOwner: defaultListOwnerTransferUserId,
        };

        await graphql(query, variables);
      }

      await deactivatePerson(person.userId, listTransferUserId);

      refreshData();
      presentToast(t('feedback.person.deleted'), 8000, 'bottom', 'dark');
      await dismissLoading();
      dismissDeleteConfirmation();
      dismissDeleteModal(true);
    } catch (err) {
      console.error('Failed to delete user', err);
      presentToast('An error occurred when trying to delete a user. Please try again.', 6000, 'top', 'error');
    }
  }

  async function dismissDeleteConfirmationModal() {
    dismissDeleteConfirmation();
  }

  return (
    <>
      {/* Header */}
      <IonHeader className="ion-no-border">
        <IonToolbar className="ion-no-border">
          <IonTitle className="font-26" style={{ paddingTop: '2rem' }}>
            Before we delete {person.firstName}...
          </IonTitle>
          <IonButtons style={{ alignSelf: 'start', paddingTop: '0.75rem' }} slot="end">
            {/* Close button */}
            <IonButton onClick={() => dismissDeleteModal(false)}>
              <IonIcon icon={closeOutline} size="large" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {/* Body */}
      <div style={{ padding: '1rem 2rem' }}>
        {/* 1st question if the user owns a list */}
        <div>
          <p className="ion-no-margin">What should we do with {person.firstName}’s lists?</p>
          <IonRadioGroup value={radioGroupValue} onIonChange={(e) => setRadioGroupValue(e.detail.value)}>
            <IonItem className="ion-no-padding" style={{ '--min-height': '0px' }} lines="none">
              <IonRadio labelPlacement="end" className="ion-no-margin" value="delete" slot="start">
                {' '}
                <IonText className={`${radioGroupValue === 'delete' ? 'font-weight-600' : ''}`}>
                  Delete the lists
                </IonText>
              </IonRadio>
            </IonItem>
            <IonItem className="ion-no-padding" style={{ '--min-height': '0px' }} lines="none">
              <IonRadio labelPlacement="end" className="ion-no-margin" value="transfer" slot="start">
                {' '}
                <IonText className={`${radioGroupValue === 'transfer' ? 'font-weight-600' : ''}`}>
                  Transfer the lists to:
                </IonText>{' '}
              </IonRadio>
            </IonItem>
          </IonRadioGroup>

          {/* Person Picker */}
          <div className="ion-margin-top ion-padding-start">
            <PersonPickerSearch
              people={people}
              selectedPersonId={listTransferUserId}
              onIonChange={onListTransferUserIdChange}
              disabled={radioGroupValue !== 'transfer'}
              placeholder={radioGroupValue !== 'transfer' ? '----' : 'Choose a person'}
              disabledUsersId={[person.userId]}
            />
            {/* Link to list view page */}
            {/* TODO: Link page */}
            {/* TODO: The modal and dialog render outside the router outlet. It looks like we won't be able to add these links here. */}
            {/* <div
              className="d-flex ion-align-items-center ion-justify-content-end font-14 font-weight-500"
              style={{ marginTop: '0.25rem' }}
            >
              <IonText color="primary" style={{ marginRight: '0.25rem' }}>
                <p className="ion-no-margin">Take me to Lists instead</p>
              </IonText>
              <IonIcon icon={openOutline} color="primary" size="small" style={{ paddingBottom: '.25rem' }} />
            </div> */}
          </div>
        </div>

        {/* 2nd question if the user is the list owner for locations ? idk */}
        {/* TODO: Not sure how we could check this yet so I will hide this section for now / not part of the 75% */}
        {false && (
          <div>
            <p>Who will replace {person.firstName} as the Default List Owner for (9) Locations?</p>

            {/* Person Picker */}
            <div className="ion-padding-start">
              <PersonPickerSearch people={people} placeholder="Choose a person" />
              {/* Link to list view page */}
              <div
                className="d-flex ion-align-items-center ion-justify-content-end font-14 font-weight-500"
                style={{ marginTop: '0.25rem' }}
              >
                <IonText color="primary" style={{ marginRight: '0.25rem' }}>
                  <p className="ion-no-margin">Take me to Locations instead</p>
                </IonText>
                <IonIcon icon={openOutline} color="primary" size="small" style={{ paddingBottom: '.25rem' }} />
              </div>
            </div>
          </div>
        )}

        {/* 3rd question if the user is the default list onwer for the organization */}
        {isConnected && person.userId === defaultListOwner && (
          <div>
            <p>Who will replace {person.firstName} as the Default List Owner for your organization account?</p>

            {/* Person Picker */}
            <div className="ion-padding-start">
              <PersonPickerSearch
                people={people}
                selectedPersonId={defaultListOwnerTransferUserId ?? defaultListOwner}
                onIonChange={onDefaultListOwnerTransferUserIdChange}
                placeholder="Choose a person"
                disabledUsersId={[person.userId]}
              />
              {/* Link to list view page */}
              {getFlag(EnvironmentConfig.SHOW_INTEGRATION_SUB_PAGES) && (
                <div
                  className="d-flex ion-align-items-center ion-justify-content-end font-14 font-weight-500"
                  style={{ marginTop: '0.25rem' }}
                >
                  <IonText color="primary" style={{ marginRight: '0.25rem' }}>
                    <p className="ion-no-margin">Take me to Locations instead</p>
                  </IonText>
                  <IonIcon icon={openOutline} color="primary" size="small" style={{ paddingBottom: '.25rem' }} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Footer */}
      <IonFooter className="ion-no-border" style={{ padding: '2rem 1.25rem', paddingTop: '0' }}>
        <IonToolbar>
          <IonButtons slot="start" onClick={() => dismissDeleteModal(false)}>
            <IonButton className="font-20" color="primary">
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              className="font-20"
              color="danger"
              disabled={disabledDeleteButtonConditionCheck()}
              onClick={() => presentDeleteConfirmationModal()}
            >
              Delete {person.firstName}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default NewDeletePersonDialog;
