import {
  IonHeader,
  IonTitle,
  IonIcon,
  IonList,
  IonDatetime,
  IonDatetimeButton,
  IonItem,
  IonLabel,
  IonPopover,
  IonFooter,
  IonButton,
  IonNote,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import PersonPickerSearch from '../../../components/personPicker/PersonPickerSearch';
import useGraphqlQuery from '../../../hooks/useGraphqlQuery';
import { getAllPeople } from '../../people/peopleApi';
import { format, formatISO, isAfter, isBefore } from 'date-fns';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dateRangeState, selectedEmployeesState, userShiftDataState } from '../recoil/atoms';
import { checkForWorkLogOverlap, generateWorkLogRecord } from '../utils/timeTrackingUtils';
import { sendTimeTrackingEvents } from '../api/TimeTrackingAPI';
import CustomTimePicker from './CustomTimePicker';

interface AddWorkLogRecordDialogProps {
  dismiss: () => void;
}

const AddWorkLogRecordDialog: React.FC<AddWorkLogRecordDialogProps> = ({ dismiss }) => {
  const [selectedPersonId, setSelectedPersonId] = useState<string | null>(null);
  const [shiftData, setShiftData] = useRecoilState(userShiftDataState);
  const selectedEmployees = useRecoilValue(selectedEmployeesState);
  const dateRange = useRecoilValue(dateRangeState);

  const [startTimeOnly, setStartTimeOnly] = useState<boolean>(true);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [startTimeTime, setStartTimeTime] = useState<string | null>(null);
  const [startTimeError, setStartTimeError] = useState<string | null>(null);

  const [endTime, setEndTime] = useState<Date | null>(null);
  const [endTimeError, setEndTimeError] = useState<string | null>(null);

  const { data: people } = useGraphqlQuery(getAllPeople);

  const onStartDateChange = (e: any) => {
    const newStartTime = new Date(e);

    if (!startTimeOnly && endTime && isAfter(newStartTime, endTime)) {
      setStartTimeError('Start time cannot be after end time');
      setStartTime(new Date(newStartTime));
      return;
    }

    if (startTimeError !== 'Invalid time value') setStartTimeError(null);
    if (endTimeError !== 'Invalid time value') setEndTimeError(null);
    setStartTime(new Date(newStartTime));
  };

  const onEndDateChange = (e: any) => {
    console.log('End Date Changed', e);
    const newEndTime = new Date(e);

    if (startTime && isBefore(newEndTime, startTime)) {
      setEndTimeError('End time cannot be before start time');
      setEndTime(new Date(newEndTime));
      return;
    }

    if (startTimeError !== 'Invalid time value') setStartTimeError(null);
    if (endTimeError !== 'Invalid time value') setEndTimeError(null);
    setEndTime(new Date(newEndTime));
  };

  const onPersonChange = (e: any) => {
    // This returns the person ID
    setSelectedPersonId(e.detail.value);
  };

  const onSaveClickHandler = () => {
    if (!selectedPersonId || !startTime) return;
    const hasWorkLogOverlap = checkForWorkLogOverlap(startTime, endTime, shiftData, selectedPersonId!);

    if (hasWorkLogOverlap && !startTimeOnly) {
      setStartTimeError('Start time and end time overlap with existing work logs');
      setEndTimeError('Start time and end time overlap with existing work logs');
      return;
    }

    console.log('Person ID:', selectedPersonId, 'Start Time:', startTime, 'End Time:', endTime);
    const { worklog: newWorkLogRecord, timeTrackingEvents } = generateWorkLogRecord(
      startTime,
      startTimeOnly ? null : endTime
    );

    if (startTime) {
      if (isAfter(startTime, new Date())) {
        setStartTimeError('Time cannot be in the future');
        return;
      }
    }

    if (endTime) {
      if (isAfter(endTime, new Date())) {
        setEndTimeError('Time cannot be in the future');
        return;
      }
    }

    sendTimeTrackingEvents(startTimeOnly ? [timeTrackingEvents[0]] : timeTrackingEvents, selectedPersonId!)
      .then(() => {
        // Update the local state with the new work log record
        setShiftData((prev) => {
          const newShiftData = [...prev];
          console.log('Shift Data:', newShiftData);

          const findUserIndex = newShiftData.findIndex((shift) => shift.userId === selectedPersonId);
          if (findUserIndex !== -1) {
            const newObject: any = { ...newShiftData[findUserIndex] };
            const newWorkLogs = [...newObject.workLogs, newWorkLogRecord];
            newObject.workLogs = newWorkLogs;
            newShiftData[findUserIndex] = newObject;
          }

          if (findUserIndex === -1) {
            const newObject = {
              userId: selectedPersonId!,
              workLogs: [newWorkLogRecord],
            };
            newShiftData.push(newObject as any);
          }

          return newShiftData;
        });
        dismiss();
      })
      .catch((error) => {
        console.error('Error sending time tracking events:', error);
      });
  };

  const onStartTimeChangeEventHandler = (value: string | null) => {
    if (!value) return;
    const [hours, minutes] = value.split(':');
    if (!startTime) return;
    setStartTimeTime(value);
    setStartTime((prevStartTime) => {
      const newStartTime = new Date(prevStartTime!);
      newStartTime.setHours(Number(hours));
      newStartTime.setMinutes(Number(minutes));
      return newStartTime;
    });
    setStartTimeError(null);
  };

  const onEndTimeChangeEventHandler = (value: string | null) => {
    if (value === null) {
      setEndTimeError(null);
      setStartTimeOnly(true);
      return;
    }
    const [hours, minutes] = value.split(':');

    if (!endTime) return;

    setStartTimeOnly(false);

    const newValue = new Date(endTime!);
    newValue.setHours(Number(hours));
    newValue.setMinutes(Number(minutes));

    if (isBefore(newValue, startTime!)) {
      console.log('here');
      setEndTimeError('End time cannot be before start time');
      setEndTime(new Date(newValue));
      return;
    }

    setEndTime((prevEndTime) => {
      const newEndTime = new Date(prevEndTime!);
      newEndTime.setHours(Number(hours));
      newEndTime.setMinutes(Number(minutes));
      return newEndTime;
    });
    setEndTimeError(null);
  };

  const peopleToDisplay = people?.filter((person) => selectedEmployees.includes(person.userId));

  return (
    <div className="ion-padding">
      <IonHeader className="ion-no-border d-flex ion-justify-content-between">
        <IonTitle>Add Time Record</IonTitle>
        <IonIcon className="clickable ion-padding-end" icon={closeOutline} size="large" onClick={() => dismiss()} />
      </IonHeader>
      <div>
        <div className="ion-padding" style={{ paddingBottom: '0px' }}>
          <IonLabel className="font-12">User Name:</IonLabel>
          <PersonPickerSearch people={peopleToDisplay ?? []} onIonChange={onPersonChange} />
        </div>

        <IonList>
          <IonItem lines="none" className={`${startTimeError ? 'ion-invalid' : ''}`}>
            <IonLabel position="stacked">Start Date:</IonLabel>
            <div className="d-flex ion-align-items-center" style={{ marginTop: '8px' }}>
              <IonDatetimeButton datetime="edit-start-date-datetime">
                {!startTime && <div slot="date-target">Select a Date</div>}
                {startTime && <div slot="date-target">{format(startTime, 'MM/dd/yyyy')}</div>}
              </IonDatetimeButton>
              <CustomTimePicker
                value={startTime ? format(startTime, 'HH:mm') : ''}
                onChange={onStartTimeChangeEventHandler}
                onError={(error) => setStartTimeError(error)}
              />
            </div>
            <IonNote slot="error">{startTimeError}</IonNote>

            <IonPopover keepContentsMounted={true}>
              <IonDatetime
                presentation="date"
                id="edit-start-date-datetime"
                mode="ios"
                className="time-tracking"
                value={startTime ? formatISO(startTime) : undefined}
                onIonChange={(e) => onStartDateChange(e.detail.value)}
                min={formatISO(dateRange[0]!)}
                max={formatISO(dateRange[1]!)}
              ></IonDatetime>
            </IonPopover>
          </IonItem>

          <IonItem
            disabled={startTime && startTimeTime ? false : true}
            lines="none"
            className={`${endTimeError ? 'ion-invalid' : ''}`}
          >
            <IonLabel position="stacked">End Date:</IonLabel>
            <div className="d-flex ion-align-items-center" style={{ marginTop: '8px' }}>
              <IonDatetimeButton datetime="edit-end-date-datetime">
                {!endTime && (
                  <div slot="date-target" style={{ opacity: '1' }}>
                    Select a Date
                  </div>
                )}
                {endTime && (
                  <div slot="date-target" style={{ opacity: '1' }}>
                    {format(endTime, 'MM/dd/yyyy')}
                  </div>
                )}
              </IonDatetimeButton>
              <CustomTimePicker
                value={endTime ? format(endTime, 'HH:mm') : ''}
                onChange={onEndTimeChangeEventHandler}
                onError={(error) => setEndTimeError(error)}
              />
            </div>
            <IonNote slot="error">{endTimeError}</IonNote>

            <IonPopover keepContentsMounted={true}>
              <IonDatetime
                presentation="date"
                id="edit-end-date-datetime"
                mode="ios"
                className="time-tracking"
                value={endTime ? formatISO(endTime) : undefined}
                onIonChange={(e) => onEndDateChange(e.detail.value)}
                min={formatISO(dateRange[0]!)}
                max={formatISO(dateRange[1]!)}
              ></IonDatetime>
            </IonPopover>
          </IonItem>
        </IonList>
        <div></div>
        {/* TODO: Show to total working hours for this new record */}
      </div>
      <IonFooter className="ion-no-border">
        <div className="d-flex ion-justify-content-end ion-align-items-center ion-padding">
          <IonButton
            disabled={
              startTimeError !== null ||
              endTimeError !== null ||
              selectedPersonId === null ||
              !startTime ||
              !startTimeTime
            }
            onClick={onSaveClickHandler}
          >
            Save
          </IonButton>
        </div>
      </IonFooter>
    </div>
  );
};

export default AddWorkLogRecordDialog;
