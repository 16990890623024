import {
  IonButton,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  useIonModal,
} from '@ionic/react';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from '../../../../constants/constants';
import { Person } from '../../peopleApi';
import './EditPersonDialog.css';
import '../../../../theme/formClasses.css';
import usePeopleValidations from '../../hooks/usePeopleValidations';
import { closeOutline } from 'ionicons/icons';
import { AuthCache, UserGroups } from '@otuvy/auth';
import NewDeletePersonDialog from '../DeletePersonDialog/NewDeletePersonDialog';

interface EditPersonDialogProps {
  dismiss: () => void;
  person: Person;
  refreshData: () => void;
  savePerson: (updatedPerson: Person) => void;
}

const EditPersonDialog: React.FC<EditPersonDialogProps> = ({ dismiss, person, refreshData, savePerson }) => {
  const [personInfo, setPersonInfo] = useState<Person>(person);
  const [errorKeys, setErrorKeys] = useState<string[]>([]);

  const { t } = useTranslation();
  const { validateProp } = usePeopleValidations(setErrorKeys);

  const isPersonTechSupport = personInfo.groups.includes(UserGroups.OTUVY_TECH_SUPPORT);
  const isPersonSuperAdmin = personInfo.groups.includes(UserGroups.SUPER_ADMIN);

  const handleSubmit = (event: MouseEvent | KeyboardEvent | FormEvent | CustomEvent<FocusEvent>) => {
    event.preventDefault();
    save();
  };

  const dismissDeleteModal = (shouldDismissAll?: boolean) => {
    dismissDelete();
    if (shouldDismissAll) {
      dismiss();
    }
  };

  const [presentDelete, dismissDelete] = useIonModal(NewDeletePersonDialog, {
    dismissDeleteModal: dismissDeleteModal,
    refreshData,
    person: person,
  });

  const handleChange = (event: any) => {
    validateProp(event.target.name, event.target.value);
    setPersonInfo({ ...personInfo, [event.target.name]: event.target.value });
  };

  const save = () => {
    const updatedPerson: Person = {
      ...person,
      ...personInfo,
    };

    savePerson(updatedPerson);
  };

  return (
    <div className="ion-padding">
      <IonHeader className="ion-no-border d-flex ion-justify-content-between">
        <IonTitle>Edit Person</IonTitle>
        <IonIcon className="clickable ion-padding-end" icon={closeOutline} size="large" onClick={dismiss} />
      </IonHeader>
      <IonList>
        <form onSubmit={handleSubmit} className="custom-form">
          <IonItem lines="none" className={`${errorKeys.includes('firstName') && 'ion-invalid'}`}>
            <IonLabel position="stacked">{t('label.user.firstName')}</IonLabel>
            <IonInput
              name="firstName"
              value={personInfo.firstName}
              type="text"
              placeholder={t('placeholder.firstName')}
              autocomplete="given-name"
              onIonInput={(e) => handleChange(e)}
              disabled={isPersonTechSupport}
            />
            <IonNote slot="error">Please enter a first name</IonNote>
          </IonItem>

          <IonItem lines="none" className={`${errorKeys.includes('lastName') && 'ion-invalid'}`}>
            <IonLabel position="stacked">{t('label.user.lastName')}</IonLabel>
            <IonInput
              name="lastName"
              value={personInfo.lastName}
              type="text"
              placeholder={t('placeholder.lastName')}
              autocomplete="family-name"
              onIonInput={(e) => handleChange(e)}
              disabled={isPersonTechSupport}
            />
            <IonNote slot="error">Please enter a last name</IonNote>
          </IonItem>

          <IonItem lines="none" className={`${errorKeys.includes('email') && 'ion-invalid'}`}>
            <IonLabel position="stacked">{t('label.user.email')}</IonLabel>
            <IonInput
              disabled={isPersonTechSupport || isPersonSuperAdmin}
              type="email"
              name="email"
              value={personInfo.email}
              placeholder={t('placeholder.email')}
              autocomplete="email"
              onIonInput={(e) => handleChange(e)}
            />
            <IonNote slot="error">Please enter a valid email</IonNote>
          </IonItem>

          <div>
            <IonItem lines="none">
              <IonLabel position="stacked">{t('label.user.language')}</IonLabel>
              <IonSelect
                name="language"
                value={personInfo.language}
                onIonChange={(e) => handleChange(e)}
                disabled={isPersonTechSupport}
              >
                <IonSelectOption value={Language.ENGLISH}>{t(`language.name.${Language.ENGLISH}`)}</IonSelectOption>
                <IonSelectOption value={Language.ESPAÑOL}>{t(`language.name.${Language.ESPAÑOL}`)}</IonSelectOption>
                <IonSelectOption value={Language.FRANCAIS}>{t(`language.name.${Language.FRANCAIS}`)}</IonSelectOption>
                <IonSelectOption value={Language.PORTUGUES}>{t(`language.name.${Language.PORTUGUES}`)}</IonSelectOption>
              </IonSelect>
            </IonItem>
          </div>

          {/*
            Do not Delete these commented out lines. 
            I was told to save them for future functionality!
          */}

          {/*<div>*/}
          {/*  <IonItem lines="none">*/}
          {/*    <IonLabel position="stacked">{t('status')}</IonLabel>*/}
          {/*    <IonInput disabled={true} name="email" value={personInfo.status === 1 ? 'Active' : 'Inactive'} />*/}
          {/*<IonSelect*/}
          {/*  disabled={personInfo.role === 4 ? true : false}*/}
          {/*  name="status"*/}
          {/*  value={personInfo.status}*/}
          {/*  onIonChange={(e) => handleChange(e)}*/}
          {/*>*/}
          {/*  <IonSelectOption value={Status.ACTIVE}>Active</IonSelectOption>*/}
          {/*  <IonSelectOption value={Status.INACTIVE}>Inactive</IonSelectOption>*/}
          {/*</IonSelect>*/}
          {/*  </IonItem>*/}
          {/*</div>*/}
        </form>
      </IonList>
      <IonFooter className="ion-no-border">
        <div className="d-flex ion-justify-content-between ion-align-items-center ion-padding">
          {/*kept empty IonText to preserve the layout*/}
          {isPersonTechSupport || isPersonSuperAdmin || personInfo.userId === AuthCache.getCurrentUserId() ? ( //People can not delete themselves
            <IonText></IonText>
          ) : (
            <IonText className="clickable" color={'medium'} onClick={() => presentDelete()} id="delete-person-modal">
              Delete This User
            </IonText>
          )}
          <IonButton
            disabled={JSON.stringify(person) === JSON.stringify(personInfo) || errorKeys.length > 0}
            onClick={save}
          >
            Save
          </IonButton>
        </div>
      </IonFooter>
    </div>
  );
};

export default EditPersonDialog;
