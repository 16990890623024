import { useEffect, useState } from 'react';
import styles from './SurveyBanner.module.css';

const MS_IN_A_DAY = 1000 * 60 * 60 * 24;

// Market fit survey constants
const SURVEY_KEY = 'survey_timestamp';
const SURVEY_DISMISS_KEY = 'survey_dismiss_timestamp';
const SURVEY_URL = 'https://survey.otuvy.com/zs/xdD4Is';

// Leadership survey constants
const SHOW_LEADERSHIP_SURVEY = true;
const LEADERSHIP_SURVEY_DISMISS_KEY = 'leadership_survey_dismiss_timestamp';
const LEADERSHIP_SURVEY_URL = 'https://survey.otuvy.com/zs/SYCYBe';
const LEADERSHIP_SURVEY_TITLE = 'Help us make Otuvy even better!';
const LEADERSHIP_SURVEY_BODY = 'Please take one minute to share your feedback and shape our next updates.';
const LEADERSHIP_SURVEY_BUTTON_TEXT = "Let's do it!";

const shouldShowSurvey = () => {
  const currentDate = Date.now();
  const firstLogin = localStorage.getItem(SURVEY_KEY);
  const lastDismiss = localStorage.getItem(SURVEY_DISMISS_KEY);

  if (!firstLogin) {
    localStorage.setItem(SURVEY_KEY, currentDate.toString());
    return false;
  }

  const daysSinceLogin = (currentDate - Number(firstLogin)) / MS_IN_A_DAY;
  const daysSinceDismiss = lastDismiss ? (currentDate - Number(lastDismiss)) / MS_IN_A_DAY : Infinity;

  return daysSinceLogin >= 45 && daysSinceDismiss >= 90;
};

const shouldShowLeadershipSurvey = () => {
  if (!SHOW_LEADERSHIP_SURVEY) return false;

  const currentDate = Date.now();
  const lastDismiss = localStorage.getItem(LEADERSHIP_SURVEY_DISMISS_KEY);

  if (!lastDismiss) return true;

  const daysSinceDismiss = (currentDate - Number(lastDismiss)) / MS_IN_A_DAY;

  return daysSinceDismiss >= 90;
};

const SurveyBanner: React.FC = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [shouldRenderMarketFitSurvey, setShouldRenderMarketFitSurvey] = useState(false);
  const [shouldRenderLeadershipSurvey, setShouldRenderLeadershipSurvey] = useState(false);

  useEffect(() => {
    if (shouldShowLeadershipSurvey()) {
      setShouldRenderLeadershipSurvey(true);
      return;
    }

    if (shouldShowSurvey()) {
      setShouldRenderMarketFitSurvey(true);
      return;
    }
  }, []);

  useEffect(() => {
    if (!shouldRenderMarketFitSurvey && !shouldRenderLeadershipSurvey) return;

    const timeoutId = setTimeout(() => {
      setIsBannerVisible(true);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [shouldRenderMarketFitSurvey, shouldRenderLeadershipSurvey]);

  const closeBanner = () => {
    shouldRenderMarketFitSurvey
      ? localStorage.setItem(SURVEY_DISMISS_KEY, Date.now().toString())
      : localStorage.setItem(LEADERSHIP_SURVEY_DISMISS_KEY, Date.now().toString());
    setIsBannerVisible(false);
  };

  const openSurvey = () => {
    window.open(shouldRenderMarketFitSurvey ? SURVEY_URL : LEADERSHIP_SURVEY_URL, '_blank');
    shouldRenderMarketFitSurvey
      ? localStorage.setItem(SURVEY_DISMISS_KEY, Date.now().toString())
      : localStorage.setItem(LEADERSHIP_SURVEY_DISMISS_KEY, Date.now().toString());
    setIsBannerVisible(false);
  };

  if (!shouldRenderMarketFitSurvey && !shouldRenderLeadershipSurvey) return null;

  return (
    <div className={`${styles.container} ${!isBannerVisible && styles.hidden} ${isBannerVisible && styles.visible}`}>
      <div onClick={closeBanner} className={`${styles.closeBannerButton}`}>
        &#x2715;
      </div>
      <div className={`${styles.title}`}>
        {shouldRenderMarketFitSurvey ? 'Please tell us about your experience with Otuvy.' : LEADERSHIP_SURVEY_TITLE}
      </div>
      {shouldRenderLeadershipSurvey && <div className={`${styles.body}`}>{LEADERSHIP_SURVEY_BODY}</div>}
      <button className={`${styles.button}`} onClick={openSurvey}>
        {shouldRenderMarketFitSurvey ? 'Take the survey' : LEADERSHIP_SURVEY_BUTTON_TEXT}
      </button>
      <div onClick={closeBanner} className={`${styles.remmindMeLater}`}>
        Remind me later
      </div>
    </div>
  );
};

export default SurveyBanner;
