import React, { useEffect } from 'react';
import { IonItem, IonDatetime, IonRadio, IonRadioGroup, IonText, IonPopover, IonDatetimeButton } from '@ionic/react';
import { useRecoilState } from 'recoil';
import { endOfDay, endOfWeek, formatISO, startOfDay, startOfWeek, subWeeks } from 'date-fns';
import { dateRangeState } from '../recoil/atoms';
import StepsLabel from './StepsLabel';

const DateRangeSelector: React.FC = () => {
  const [dateRange, setDateRange] = useRecoilState(dateRangeState);
  const [timeFrame, setTimeFrame] = React.useState<string>('today');
  const [isCustomRange, setIsCustomRange] = React.useState<boolean>(false);

  useEffect(() => {
    if (timeFrame === 'Last calendar week') {
      const today = new Date();
      const lastWeekEnd = endOfWeek(subWeeks(today, 1), { weekStartsOn: 0 });
      const lastWeekStart = startOfWeek(lastWeekEnd, { weekStartsOn: 0 });
      setIsCustomRange(() => false);
      setDateRange([lastWeekStart, lastWeekEnd]);
    } else if (timeFrame === 'Last two calendar weeks') {
      const today = new Date();
      const twoWeeksAgoEnd = endOfWeek(subWeeks(today, 1), { weekStartsOn: 0 });
      const twoWeeksAgoStart = startOfWeek(subWeeks(twoWeeksAgoEnd, 1), { weekStartsOn: 0 });
      setIsCustomRange(() => false);
      setDateRange([twoWeeksAgoStart, twoWeeksAgoEnd]);
    } else if (timeFrame === 'Custom date range') {
      const end = new Date();
      const start = startOfDay(new Date());
      setDateRange([start, end]);
      setIsCustomRange(() => true);
    }
  }, [timeFrame, isCustomRange]);

  const handleDateChange = (index: number, value: string) => {
    if (isCustomRange === false) return;
    const newDate = new Date(value);
    const startDate = index === 0 ? startOfDay(newDate) : dateRange[0];
    const endDate = index === 1 ? endOfDay(newDate) : dateRange[1];
    setDateRange([startDate, endDate]);
  };

  return (
    <div>
      <StepsLabel stepTitle="1. Choose Timeframe" StepSubtitle="Include data from this date range..." />
      <IonRadioGroup value={timeFrame} onIonChange={(e) => setTimeFrame(e.detail.value)}>
        <IonItem lines="none" style={{ '--min-height': '0px' }}>
          <IonRadio labelPlacement="end" slot="start" value="Last calendar week">
            Last full calendar week
          </IonRadio>
        </IonItem>
        <IonItem lines="none" style={{ '--min-height': '0px' }}>
          <IonRadio labelPlacement="end" slot="start" value="Last two calendar weeks">
            Last two full calendar weeks
          </IonRadio>
        </IonItem>
        <IonItem lines="none" style={{ '--min-height': '0px' }}>
          <IonRadio labelPlacement="end" slot="start" value="Custom date range">
            Custom date range
          </IonRadio>
        </IonItem>
      </IonRadioGroup>
      {isCustomRange && (
        <div style={{ opacity: isCustomRange ? '1' : '0', pointerEvents: isCustomRange ? 'all' : 'none' }}>
          <div className="ion-padding-start" style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
            <div className="d-flex ion-align-items-center" style={{ whiteSpace: 'nowrap' }}>
              <IonText style={{ marginRight: '4px' }}>First Date:</IonText>
              <IonDatetimeButton datetime="startCustomDatetime" />
            </div>
            <div className="d-flex ion-align-items-center" style={{ whiteSpace: 'nowrap' }}>
              <IonText style={{ marginRight: '4px' }}>Second Date:</IonText>
              <IonDatetimeButton datetime="endCustomDatetime" />
            </div>
          </div>

          <IonPopover keepContentsMounted={true}>
            <IonDatetime
              id="startCustomDatetime"
              mode="ios"
              className="time-tracking"
              presentation="date"
              value={dateRange[0] ? formatISO(dateRange[0]) : formatISO(startOfDay(new Date()))}
              onIonChange={(e) => handleDateChange(0, e.detail.value as string)}
              max={dateRange[1] ? formatISO(dateRange[1]!) : undefined}
            />
          </IonPopover>
          <IonPopover keepContentsMounted={true}>
            <IonDatetime
              id="endCustomDatetime"
              mode="ios"
              className="time-tracking"
              presentation="date"
              value={dateRange[1] ? formatISO(dateRange[1]) : formatISO(new Date())}
              min={dateRange[0] ? formatISO(dateRange[0]!) : undefined}
              onIonChange={(e) => handleDateChange(1, e.detail.value as string)}
            />
          </IonPopover>
        </div>
      )}
    </div>
  );
};

export default DateRangeSelector;
