// This file has some constants that need to be maintained in sync with the other projects

export class MaxFieldLengths {
  public static readonly LIST_NAME = 500;
  public static readonly LIST_NOTES = 500;
  public static readonly TASK_NAME = 500;
  public static readonly TASK_INSTRUCTIONS = 1000;
  public static readonly TASK_INSTRUCTIONS_LINK_URL = 1000;
  public static readonly TASK_INSTRUCTIONS_LINK_TEXT = 250;
  public static readonly TASK_NOTES = 1000;
  public static readonly TASK_GENERATION_TYPE_OF_TASKS = 500;
  public static readonly TASK_GENERATION_LIST_MAIN_OBJECTIVE = 500;
  public static readonly REPORT_NAME = 100;
}

export const LONG_STRING_MAX_LENGTH: number = 80;

export enum Status {
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum Language {
  INVALID = 'invalid',
  ENGLISH = 'en',
  ESPAÑOL = 'es',
  FRANCAIS = 'fr',
  PORTUGUES = 'pt',
}

export const URL_PATH_PREFIX = '';

export const enum GovernorLevelKey {
  PAST_DUE = 'payment_past_due',
  STORAGE = 'storage',
}
export const GOVERNOR_LEVEL_LIMIT = 100;
export const GOVERNOR_LEVEL_RESTRICTED = 110;
export const GOVERNOR_LEVEL_LOCKED = 120;

export interface GovernorLevelConstantsGroup {
  key: GovernorLevelKey;
  WARNING_LEVEL_1_THRESHOLD_PERCENT?: number;
  WARNING_LEVEL_2_THRESHOLD_PERCENT?: number;
  DAYS_BEFORE_WRITE_ACCESS_RESTRICTED: number;
  DAYS_BEFORE_LOCKED: number;
  DAYS_BEFORE_DELETED: number;
}
export const GovernorLevelConstants: GovernorLevelConstantsGroup[] = [
  {
    key: GovernorLevelKey.PAST_DUE,
    DAYS_BEFORE_WRITE_ACCESS_RESTRICTED: 30,
    DAYS_BEFORE_LOCKED: 45,
    DAYS_BEFORE_DELETED: 90,
  },
  {
    key: GovernorLevelKey.STORAGE,
    WARNING_LEVEL_1_THRESHOLD_PERCENT: 90,
    WARNING_LEVEL_2_THRESHOLD_PERCENT: 97,
    DAYS_BEFORE_WRITE_ACCESS_RESTRICTED: 30,
    DAYS_BEFORE_LOCKED: 45,
    DAYS_BEFORE_DELETED: 90,
  },
];

export const enum AgreementTypes {
  PURCHASE_AGREEMENT = 'Purchase Agreement',
  TERMS_AND_CONDITIONS = 'Terms and Conditions',
  PRIVACY_POLICY = 'Privacy Policy',
}

export const enum AgreementStatusTypes {
  UNACCEPTED = 'UNACCEPTED',
  ACCEPTED = 'ACCEPTED',
  REACCEPTANCE_REQUIRED = 'REACCEPTANCE_REQUIRED',
}

export interface AgreementStatus {
  agreementType: AgreementTypes;
  agreementStatus: AgreementStatusTypes;
}

export interface GeneralError {
  errorKey: ImportGeneralErrorKey;
  errorMessage?: string;
  errorDetail: object;
}

//TODO: move these to the npm package so they can be shared once we get it up and running
export const enum ImportGeneralErrorKey {
  UNKNOWN = 'import.unknown',

  /**
   * When the Lambda running the import starts getting close to the timeout limit
   */
  TIMEOUT = 'import.timeout',

  /**
   * This should be an edge case only encountered when the admin and back end are out of sync
   */
  BATCH_TOO_LARGE = 'import.batchTooLarge',

  /**
   * This *should* be edge cases mostly encountered when there is data corruption, or manual editing of the API call
   */
  NO_ORGANIZATION = 'import.noOrganizationForUser',

  /**
   * This *should* be edge cases mostly encountered when there is data corruption, or manual editing of the API call
   */
  NO_ORG_SUPERADMIN = 'import.noSuperAdminForOrganization',

  /**
   * This *should* be caught by the client before uploading
   */
  INVALID_DATA = 'import.invalidData',
}

export enum UserImportGeneralErrorKey {
  /**
   * This *should* be caught by the client before uploading
   */
  DUPLICATE_EMAIL = 'userImport.duplicateEmail',
}

export type UserImportGeneralError = Omit<GeneralError, 'errorKey'> & {
  errorKey: ImportGeneralErrorKey | UserImportGeneralErrorKey;
};

//TODO: move these to the npm package so they can be shared once we get it up and running
export enum UserImportSpecificErrorKey {
  /**
   * We are getting different reports bout when (never, only when ther admin) and what about (name onlym, no email or role) a super-admin can be edited.
   * This may need split out into multiple errors when we start dealing with those
   */
  EDITING_SUPER_ADMIN = 'userImport.superAdminEdit',
  /**
   * This *should* be caught by the client before uploading
   */
  MISSING_EMAIL = 'userImport.userMissingEmail',
  /**
   * This *should* be caught by the client before uploading
   */
  UNSUPPORTED_LANGUAGE = 'userImport.unsupportedLanguage',

  EMAIL_IN_OTHER_ORG = 'userImport.emailExistsElsewhere',
}
