import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  useIonAlert,
  useIonLoading,
  useIonRouter,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import 'react-phone-input-2/lib/style.css';
import '../../theme/formClasses.css';
import {
  getOrganization,
  Organization,
  updateOrganizationInfo,
  UpdateOrganizationInfoProps,
} from '../organization/organizationApi';
import { AuthCache, updateCurrentUserAttribute, UserPropertyKeys } from '@otuvy/auth';
import { getCurrentUser } from '@otuvy/auth/dist/utils/authUtils';
import { useSetRecoilState } from 'recoil';
import { currentUserFullNameState } from '../../SecureApp';
import { EditPersonInput, editPerson } from '../people/peopleApi';
import { fetchAuthSession } from 'aws-amplify/auth';

const OnboardingInfo: React.FC = () => {
  const { t } = useTranslation(); //FIXME: add translations

  const setCurrentUserFullName = useSetRecoilState(currentUserFullNameState);
  // const [onboardingDone, setOnboardingDone] = useState(false);
  const [organization, setOrganization] = useState<Organization | null | undefined>();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const navigation = useIonRouter();
  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    getCurrentUser().then((user) => {
      if (user) {
        console.log('User:', user);
        setValue('firstName', user.given_name);
        setValue('lastName', user.family_name);
      }
    });
  }, []);

  useEffect(() => {
    presentLoading().then(() =>
      //Wait for the loading spinner to present so we make sure it is there when trying to dismiss it
      getOrganization().then((org) => {
        setOrganization(org);
        // If onboarding is complete, and they're here 'by accident', redirect them.
        if (org.onboardingComplete) {
          dismissLoading();
          // navigation.push('/people/list');
        } else {
          if (org.phoneNumber) {
            setValue('phoneNumber', org.phoneNumber);
          }
          if (org.name) {
            setValue('organizationName', org.name);
          }
          dismissLoading();
        }
      })
    );
  }, []);

  const doRegistration = async (data: any) => {
    await presentLoading(); //Wait for the loading spinner to present so we make sure it is there when trying to dismiss it

    const missingRequiredInfo: string[] = [
      !data.serviceArea ? 'Service Area' : '',
      !data.industry ? 'Industry' : '',
    ].filter((info) => info !== '');

    if (missingRequiredInfo.length > 0) {
      const formattedMissingInfo = missingRequiredInfo.join('</li><li>');

      dismissLoading();
      presentAlert({
        message: `Please select the following: <ul><li>${formattedMissingInfo}</li></ul>`, //TODO: when we set up translations, we can pass the missing info in to the translation as a variable
        buttons: [t('button.acknowledge')],
      });
      return;
    }

    const updateOrgProps: UpdateOrganizationInfoProps = {
      organization_id: organization!.organization_id,
      name: data.organizationName,
      phoneNumber: data.phoneNumber,
      numEmployees: Number(data.numEmployees),
      serviceArea: data.serviceArea,
      industry: data.industry,
    };

    await updateOrganizationInfo(updateOrgProps);
    await updateCurrentUserAttribute(UserPropertyKeys.firstName, data.firstName);
    await updateCurrentUserAttribute(UserPropertyKeys.lastName, data.lastName);

    const currentUserId = AuthCache.getCurrentUserId();
    if (currentUserId) {
      const updateUserProps: EditPersonInput = {
        userId: currentUserId ?? '',
        firstName: data.firstName,
        lastName: data.lastName,
      };
      await editPerson(updateUserProps);
      setCurrentUserFullName(`${data.firstName} ${data.lastName}`);
    } else {
      console.error('Current user ID is not available');
    }

    await fetchAuthSession({ forceRefresh: true });

    dismissLoading();
    navigation.push('/people/add');
  };

  return (
    <IonPage>
      <IonContent>
        <form onSubmit={handleSubmit(doRegistration)}>
          <IonGrid className="h-100 ion-padding-horizontal d-flex flex-direction-column" fixed={true}>
            <IonRow>
              <IonCol className="ion-padding-start">
                <IonText>
                  <h1>
                    <b>Welcome!</b>
                  </h1>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-padding-start">
                <IonText>
                  <h2>Let's get you set up first...</h2>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeXs="12" sizeMd="10" sizeLg="8" className="custom-form welcome-form">
                <IonList>
                  <IonItem lines="none" className="margin-bottom-4">
                    <IonLabel className="ion-margin-end">First Name:</IonLabel>
                    <IonInput
                      type="text"
                      required={true}
                      placeholder="e.g. Manuel Martinez"
                      {...register('firstName')}
                    ></IonInput>
                  </IonItem>
                  <IonItem lines="none" className="margin-bottom-4">
                    <IonLabel className="ion-margin-end">Last Name:</IonLabel>
                    <IonInput
                      type="text"
                      required={true}
                      placeholder="e.g. Manuel Martinez"
                      {...register('lastName')}
                    ></IonInput>
                  </IonItem>
                  {/* <IonItem lines="none" className="margin-bottom-4">
                    <IonLabel className="ion-margin-end ion-text-left">Phone:</IonLabel>
                    <IonInput
                      type="tel"
                      required={true}
                      placeholder="e.g. 801-555-1234"
                      {...register('phoneNumber')}
                    ></IonInput>
                  </IonItem> */}
                  <IonItem lines="none" className="margin-bottom-4">
                    <IonLabel className="ion-margin-end ion-text-left">Company:</IonLabel>
                    <IonInput
                      type="text"
                      required={true}
                      placeholder="e.g. Acme Inc."
                      {...register('organizationName')}
                    ></IonInput>
                  </IonItem>
                  <IonItem lines="none" className="margin-bottom-4">
                    <IonLabel className="ion-margin-end ion-text-left"># of employees:</IonLabel>
                    <IonInput
                      type="number"
                      required={true}
                      placeholder="e.g. 16"
                      {...register('numEmployees')}
                    ></IonInput>
                  </IonItem>
                  <IonItem lines="none" className="margin-bottom-4">
                    <IonLabel className="ion-margin-end ion-text-left">Service Area:</IonLabel>
                    <IonSelect placeholder="Local" {...register('serviceArea')}>
                      <IonSelectOption>Local</IonSelectOption>
                      <IonSelectOption>State</IonSelectOption>
                      <IonSelectOption>National</IonSelectOption>
                      <IonSelectOption>International</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel color="dark" className="ion-margin-end ion-text-left">
                      Industry:
                    </IonLabel>
                    <IonSelect placeholder="Industry" {...register('industry')}>
                      <IonSelectOption>Building Service Contractor</IonSelectOption>
                      <IonSelectOption>In House Cleaning/Maintenance</IonSelectOption>
                      <IonSelectOption>Landscaping</IonSelectOption>
                      <IonSelectOption>General Construction</IonSelectOption>
                      <IonSelectOption>Framing</IonSelectOption>
                      <IonSelectOption>Heating and Air</IonSelectOption>
                      <IonSelectOption>Electrical</IonSelectOption>
                      <IonSelectOption>Plumbing</IonSelectOption>
                      <IonSelectOption>Home inspection</IonSelectOption>
                      <IonSelectOption>Pool builder</IonSelectOption>
                      <IonSelectOption>Energy/Utilities/Oil and Gas</IonSelectOption>
                      <IonSelectOption>Healthcare</IonSelectOption>
                      <IonSelectOption>Hospitality/Tourism</IonSelectOption>
                      <IonSelectOption>Retail</IonSelectOption>
                      <IonSelectOption>Security</IonSelectOption>
                      <IonSelectOption>Other</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>

            <IonRow className="ion-justify-content-center">
              <IonCol className="ion-padding-start">
                <IonText>
                  <h2>Then comes your team...</h2>
                </IonText>
                <ol>
                  <li>You'll enter their names &amp; emails.</li>
                  <li>They'll click a link in their email, log in and start getting stuff done!</li>
                </ol>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol sizeXs="12" sizeMd="10" sizeLg="8" className="ion-text-right">
                <IonButton type="submit">Next: Add your people &gt;</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default OnboardingInfo;
